/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";

import RenderCompany from "./RenderCompany";

import { useListCompanies } from "../../hooks";
import { ListCompaniesProps } from "../../types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../DesignComponents/TextLink";
import ThemeContext from "../../../../../context/theme/themeContext";

const ListCompanies = ({ searchValue }: ListCompaniesProps) => {
    const {
        colorsFacelift: { gray200, textPlaceholder }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const {
        listRef,
        itemSizesRef,
        allCompanies,
        companyFilteredValues,
        listHeight,
        sendToQueryParams,
        getItemKey,
        getItemSize,
        resetFilterParams
    } = useListCompanies(searchValue);

    return (
        <>
            {searchValue.length > 0 && allCompanies.length === 0 ? (
                <div
                    css={css({
                        padding: "12px 16px",
                        color: textPlaceholder,
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px"
                    })}
                >
                    {t("Table##empty table##no results found##title")}
                </div>
            ) : (
                <VariableSizeList
                    ref={listRef as any}
                    itemData={{
                        listRef,
                        itemSizesRef,
                        companies: allCompanies,
                        sendToQueryParams
                    }}
                    itemKey={getItemKey}
                    height={listHeight}
                    itemCount={allCompanies.length}
                    itemSize={getItemSize}
                    width="100%"
                >
                    {RenderCompany}
                </VariableSizeList>
            )}

            <div
                css={css({
                    display: "flex",
                    padding: "7px 16px 16px 16px",
                    borderTop: `1px solid ${gray200}`
                })}
            >
                <TextLink
                    size={TEXT_LINK_SIZES.Small}
                    isDisabled={companyFilteredValues.length < 1}
                    onClick={() => resetFilterParams(true)}
                >
                    {t("Button##clear all")}
                </TextLink>
            </div>
        </>
    );
};

export default ListCompanies;
