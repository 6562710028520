import { useTranslation } from "react-i18next";

import { LegacyDevicesProps } from "./types";

import Checkbox from "../../../../DesignComponents/Checkbox";

const LegacyDevices = ({ isLoading, toggleValue }: LegacyDevicesProps) => {
    const { t } = useTranslation();

    return (
        <Checkbox
            label={t("Dialog##add new device legacy title")}
            info={t("Dialog##add new device legacy description")}
            customStyle={{ marginBottom: "16px" }}
            onChange={(_, isChecked) => toggleValue(isChecked)}
            disabled={isLoading}
        />
    );
};

export default LegacyDevices;
