/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { LabelComponentProps } from "./types";

import ThemeContext from "../../../../context/theme/themeContext";

const LabelComponent = ({
    title,
    isDisabled,
    hasInfo
}: LabelComponentProps) => {
    const {
        colorsFacelift: { textDark, textDarkDisabled }
    } = useContext(ThemeContext);

    return (
        <span
            css={css({
                color: isDisabled ? textDarkDisabled : textDark,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                marginTop: "2px",
                marginBottom: hasInfo ? "0" : "2px"
            })}
        >
            {title}
        </span>
    );
};

export { LabelComponent };
