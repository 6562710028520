import { RadioGroup } from "@mui/material";

import { DeviceRadioGroupProps } from "../types";

import Radio from "../../../../DesignComponents/Radio";

import { fileFormatBlockStyle } from "../../../../../shared";

const DeviceRadioGroup = ({
    target,
    firstRadioLabel,
    firstRadioValue,
    secondRadioLabel,
    secondRadioValue,
    isLoading,
    onChange,
    customStyle
}: DeviceRadioGroupProps) => {
    return (
        <>
            <RadioGroup
                value={target}
                onChange={onChange}
                sx={customStyle || fileFormatBlockStyle}
            >
                <Radio
                    value={firstRadioValue}
                    label={firstRadioLabel}
                    disabled={isLoading}
                />

                <Radio
                    value={secondRadioValue}
                    label={secondRadioLabel}
                    disabled={isLoading}
                />
            </RadioGroup>
        </>
    );
};

export default DeviceRadioGroup;
