import { useTranslation } from "react-i18next";

import QueryDropdown from "..";

import { CompanyDropdownProps } from "../types";

import { DROPDOWN_SIZES, ENDPOINTS, ID_TYPE } from "../../../shared";

const CompanyDropdown = ({
    companyValue,
    isLoading,
    onChange,
    customStyle,
    isRequired,
    isRootRequired
}: CompanyDropdownProps) => {
    const { t } = useTranslation();

    return (
        <QueryDropdown
            data-testid="shared-company-dropdown"
            resource={ENDPOINTS.Companies}
            idType={ID_TYPE.Id}
            value={companyValue}
            size={DROPDOWN_SIZES.Medium}
            fullWidth
            isRequired={isRequired}
            readOnly={isLoading}
            change={onChange}
            labelLeft={t("Dialog##company")}
            textFieldParams={{ placeholder: t("Dialog##select") }}
            customStyle={customStyle}
            isRootRequired={isRootRequired}
        />
    );
};

export default CompanyDropdown;
