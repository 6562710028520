export const CROWD_SUPPORT_FORUM = "https://community.teltonika.lt/";
export const GOOGLE_AUTHENTICATOR =
    "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid";
export const MICROSOFT_AUTHENTICATOR =
    "https://www.microsoft.com/en-us/security/mobile-authenticator-app";
export const TELTONIKA_IOT_GROUP_WEBSITE = "https://teltonika-iot-group.com/";
export const TELTONIKA_TELEMATICS_WEBSITE = "https://teltonika-gps.com/";
export const TELTONIKA_TELEMATICS_WEBSITE_CONTACTS = `${TELTONIKA_TELEMATICS_WEBSITE}about-us/contacts`;
export const TELTONIKA_WIKI_FOTA_WEB =
    "https://wiki.teltonika-gps.com/view/FOTA_WEB";
