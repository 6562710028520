import { useState } from "react";

import CreateTaskContent from "./CreateTaskContent";
import { SecondSelectData } from "./CreateTaskContent/SecondSelectContent/types";
import { PayloadInfo, ScheduleData } from "./types";

import { useTriSourceAction } from "../hooks";

import TriSource from "../../components/TriSource/TriSource";
import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { ALERT_STATUS } from "../../../../../context/alert/alertContext";

import {
    COMPANY_LEVEL_SETTINGS,
    ENDPOINTS,
    RESOURCE_LIMIT_SETTINGS,
    TASK_TYPES,
    TRI_SOURCES,
    useCompanyLevelSettings,
    useFormattedNumber
} from "../../../../../shared";

const CreateTask = ({ isOpen, close }: DialogProps) => {
    const {
        t,
        rootCompany,
        isSupportUser,
        activeSource,
        sourcePayload,
        isSourceReady,
        isSelectedSingleItem,
        setAlert,
        setBackgroundActionId,
        deselectAllRows,
        postData,
        bulkPostData,
        handleResponse
    } = useTriSourceAction();

    const { getResourceLimit } = useCompanyLevelSettings();
    const { renderFormattedNumber } = useFormattedNumber();

    const [isLimitNotReached, setLimitNotReached] = useState<boolean | null>(
        null
    );

    const [isLoading, setLoading] = useState(false);
    const [payloadInfo, setPayloadInfo] = useState<PayloadInfo | null>(null);

    const resourceLimit = getResourceLimit(
        RESOURCE_LIMIT_SETTINGS.TasksPerGroup
    );

    const checkIfLimitIsNotReached = (count: number) => {
        const isNotReached = resourceLimit === -1 || count <= resourceLimit;

        setLimitNotReached(isNotReached);
    };

    const isReady =
        isSourceReady &&
        isLimitNotReached &&
        sourcePayload &&
        payloadInfo !== null;

    const handleCloseDialog = () => close(DEVICE_ACTIONS.CreateTask);
    const handlePayload = (data: PayloadInfo | null) => setPayloadInfo(data);

    const getFormData = (
        type: TASK_TYPES,
        secondSelectData: SecondSelectData | null,
        schedule: ScheduleData | null,
        expire_existing_tasks: boolean
    ) => {
        if (isReady) {
            const formData = new FormData();

            const getSchedulePayload = () => {
                if (schedule === null) {
                    formData.append("data[schedule]", "null");
                } else {
                    formData.append("data[schedule][type]", schedule.type);

                    formData.append(
                        "data[schedule][attributes][from_minutes]",
                        String(schedule.attributes.from_minutes)
                    );

                    formData.append(
                        "data[schedule][attributes][duration_minutes]",
                        String(schedule.attributes.duration_minutes)
                    );
                }
            };

            formData.append("source", activeSource);
            formData.append("file", sourcePayload as Blob);

            isSupportUser &&
                formData.append("root_id", String(rootCompany?.id as number));

            formData.append("data[type]", type);

            secondSelectData &&
                formData.append(
                    `data[${secondSelectData.id}]`,
                    secondSelectData.value as Blob
                );

            formData.append(
                "data[expire_existing_tasks]",
                String(expire_existing_tasks)
            );

            getSchedulePayload();

            return formData;
        }

        return null;
    };

    const getPayloadData = (
        type: TASK_TYPES,
        secondSelectData: SecondSelectData | null,
        schedule: ScheduleData | null,
        expire_existing_tasks: boolean
    ) => {
        if (isReady) {
            const data = {
                type,
                schedule,
                expire_existing_tasks,
                ...(secondSelectData
                    ? { [secondSelectData.id]: secondSelectData.value }
                    : {}),
                ...(isSelectedSingleItem
                    ? { device_imei: (sourcePayload as number[])[0] }
                    : {})
            };

            const payload = {
                source: activeSource,
                [activeSource]: sourcePayload,
                data
            };

            return isSelectedSingleItem ? data : payload;
        }
    };

    const submitCreateTask = async () => {
        if (isReady) {
            const { type, secondSelectData, schedule, expire_existing_tasks } =
                payloadInfo;

            try {
                setLoading(true);

                const payloadData =
                    activeSource === TRI_SOURCES.FromFile
                        ? getFormData(
                              type,
                              secondSelectData,
                              schedule,
                              expire_existing_tasks
                          )
                        : getPayloadData(
                              type,
                              secondSelectData,
                              schedule,
                              expire_existing_tasks
                          );

                const { data } = await (isSelectedSingleItem
                    ? postData(ENDPOINTS.Tasks, payloadData)
                    : bulkPostData(ENDPOINTS.Tasks, payloadData));

                if (isSelectedSingleItem) {
                    deselectAllRows();
                    handleCloseDialog();

                    setAlert({
                        status: ALERT_STATUS.Success,
                        title: t("Flash##task was created", {
                            imei: (payloadData as any).device_imei,
                            taskType: t(`General##${(payloadData as any).type}`)
                        })
                    });
                } else {
                    setBackgroundActionId(
                        data,
                        () => {
                            deselectAllRows();
                            handleCloseDialog();
                        },
                        () => setLoading(false)
                    );
                }
            } catch (error) {
                handleResponse(error);
                setLoading(false);
            }
        }
    };

    const isBulkActionLoading = !isSelectedSingleItem && isLoading;

    return (
        <TriSource
            title={t("Button##create task")}
            description={t("Dialog##create task description")}
            confirmationDescription={t(
                "Dialog##confirmations##create task confirmation"
            )}
            setCount={checkIfLimitIsNotReached}
            modalName={DEVICE_ACTIONS.CreateTask}
            close={handleCloseDialog}
            isBulkActionLoading={isBulkActionLoading}
            isLoading={isLoading}
            open={isOpen}
            submit={submitCreateTask}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    loaderText={
                        !isSelectedSingleItem
                            ? t("Button##creating")
                            : undefined
                    }
                    isCancelDisabled={isBulkActionLoading}
                    isReadyToConfirm={isReady}
                    submitBtnTooltipText={
                        isLimitNotReached === false
                            ? t(
                                  `Restrictions##${COMPANY_LEVEL_SETTINGS.ResourceLimitSettings}##${RESOURCE_LIMIT_SETTINGS.TasksPerGroup}`,
                                  {
                                      limitCount:
                                          renderFormattedNumber(resourceLimit)
                                  }
                              )
                            : ""
                    }
                    confirmationButtonText={t("Button##create")}
                    cancelButtonTestId="cancel-create-task-button"
                    confirmationButtonTestId="confirm-create-task-button"
                />
            }
            TransitionProps={{
                onExited: () => {
                    setLoading(false);
                    setPayloadInfo(null);
                }
            }}
        >
            <CreateTaskContent
                isLoading={isLoading}
                setPayload={handlePayload}
            />
        </TriSource>
    );
};

export default CreateTask;
