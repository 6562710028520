/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { SendEmailCodeDescriptionProps } from "./types";

import AuthContext from "../../../../../../../context/auth/authContext";

export const SendEmailCodeDescription = ({
    disable
}: SendEmailCodeDescriptionProps) => {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <>
            <Trans
                i18nKey="Security##2FA##send email code##title"
                values={{ email: user?.email }}
                components={{
                    1: (
                        <span
                            css={css({
                                fontWeight: "700"
                            })}
                        />
                    )
                }}
            />{" "}
            {t(
                `Security##2FA##${disable ? "disable##" : ""}send email code##enter code`
            )}
        </>
    );
};
