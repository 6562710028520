import { useTranslation } from "react-i18next";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";

import { usePackages } from "./hooks";

import { CanPackageDropdownProps } from "../types";

import Autocomplete from "../../../../../DesignComponents/Autocomplete";

import { DROPDOWN_SIZES } from "../../../../../../shared";

const CanPackageDropdown = ({
    isSubmitLoading,
    change,
    customStyle,
    ...props
}: CanPackageDropdownProps &
    Omit<
        MuiAutocompleteProps<any, boolean, boolean, boolean>,
        "renderInput" | "size" | "options"
    >) => {
    const { t } = useTranslation();
    const { isLoading, packages } = usePackages();

    return (
        <Autocomplete
            {...props}
            data-testid="can-package-dropdown"
            labelLeft={t("Table##can package")}
            textFieldParams={{ placeholder: t("Dialog##select") }}
            fullWidth
            size={DROPDOWN_SIZES.Medium}
            options={packages}
            loading={isLoading}
            readOnly={isSubmitLoading}
            getOptionLabel={option => option.name}
            onChange={(_, val) => change(val)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            customStyle={customStyle}
        />
    );
};

export default CanPackageDropdown;
