import { FILTER_NAMES, TRANSLATABLE_FILTER_NAMES } from "../../../shared";

const alphabeticalSort = [
    FILTER_NAMES.GroupId,
    FILTER_NAMES.Model,
    FILTER_NAMES.ModelPlatform,
    FILTER_NAMES.CurrentFw,
    FILTER_NAMES.CurrentCfg,
    FILTER_NAMES.SpecId,
    FILTER_NAMES.ConfigurationFileId,
    TRANSLATABLE_FILTER_NAMES.FileType
];

export { alphabeticalSort };
