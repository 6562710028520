/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { TasksActionContentProps } from "./types";

import Tag, { TAG_COLORS, TAG_SIZES } from "../../../../DesignComponents/Tag";

const TasksActionContent = ({ data }: TasksActionContentProps) => {
    const { t } = useTranslation();

    return (
        <div
            css={css({
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                margin: "8px 0 16px 0"
            })}
        >
            {data.map(({ status_id, count }) => {
                const title = `${t(
                    `Task status##task count##${status_id}`
                )}: ${count}`;

                return (
                    <Tag
                        key={status_id}
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={title}
                    />
                );
            })}
        </div>
    );
};

export default TasksActionContent;
