import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    checkFilteredValues,
    findActualFilters,
    getDecodedFilterValue,
    getFilterNames,
    getFilteredListFieldNames,
    handleLoading,
    transformedFilteredItems,
    updateFilterValues,
    updateQueryValue
} from "../functions";

import useTaskTypes from "../../Actions/dialogs/Devices/CreateTask/hooks/useTaskTypes";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    Filter,
    FilterDropdownOptions,
    FilteredValue,
    FILTER_NAMES,
    useApi,
    API_IS_BEING_CANCELLED,
    FILTER_LIST_FIELD_NAMES,
    useParamsFromUrlAndBrowser,
    useDecoder,
    filteredExistingItems,
    FilterId,
    isSpecIdFilter,
    transformIdArrayWithSpecIdNotAssigned,
    handleFilterOptionsStructure
} from "../../../shared";

export const useFilteredValues = () => {
    const {
        queryParams,
        filterQueryParams,
        filteredValues: mainFilteredValues,
        filterDropdownOptions,
        setFilteredValues,
        setQueryParams,
        resetFilterParams
    } = useContext(ParamsContext);

    const {
        isInnerTable,
        tableName,
        innerTableQueryParams,
        innerTableFilteredValues,
        setInnerTableParams,
        resetInnerTableFilterParams
    } = useContext(TableContext);

    const { t } = useTranslation();

    const { currentParams } = useParamsFromUrlAndBrowser();
    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const { tasks } = useTaskTypes();
    const { decodeArray } = useDecoder();

    const filterNames = getFilterNames(currentParams);
    const filtersExist = filterNames.length > 0;

    const [isLoading, setLoading] = useState(
        handleLoading(isInnerTable, filtersExist)
    );

    const filteredValues = checkFilteredValues(
        isInnerTable,
        innerTableFilteredValues,
        mainFilteredValues
    );

    const filteredListFieldNames = getFilteredListFieldNames(
        filterNames,
        FILTER_LIST_FIELD_NAMES,
        tableName
    );

    const companyIdOptions = filterDropdownOptions[FILTER_NAMES.CompanyId];

    useEffect(() => {
        let isSubscribed = true;

        const getFilteredValues = async () => {
            try {
                setLoading(true);

                const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];
                const rootCompanyQuery = `${FILTER_NAMES.RootCompany}=${rootCompanyId}`;

                const endpoints = filteredListFieldNames.map(filterName =>
                    getData(
                        `${tableName}/filterList?${rootCompanyQuery}&field=${filterName}`
                    )
                );

                const results = await Promise.all(endpoints);

                if (isSubscribed) {
                    const getValueAndOptions = () => {
                        const filteredValue: FilteredValue[] = [];
                        const filterOptions: FilterDropdownOptions = {};

                        results.forEach(result => {
                            const {
                                config,
                                data: options
                            }: { config: any; data: Filter[] } = result;

                            const filterName: FILTER_NAMES =
                                config.url.split("field=")[1];

                            const sortedOptions = handleFilterOptionsStructure(
                                options,
                                filterName,
                                tableName,
                                tasks,
                                t
                            );

                            const filterValue = currentParams[filterName];

                            const decodedFilterValue = getDecodedFilterValue(
                                filterName,
                                filterValue,
                                decodeArray
                            );

                            const transformedValues = transformedFilteredItems(
                                filteredExistingItems(
                                    sortedOptions,
                                    decodedFilterValue
                                )
                            );

                            filteredValue.push({
                                filterName,
                                value: transformedValues
                            });

                            filterOptions[filterName] = sortedOptions;
                        });

                        return { filteredValue, filterOptions };
                    };

                    const {
                        filteredValue: value,
                        filterOptions: allFilterOptions
                    } = getValueAndOptions();

                    setFilteredValues(value, allFilterOptions);
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        if (!isInnerTable && filtersExist && companyIdOptions) {
            getFilteredValues();
        }

        return () => {
            if (!isInnerTable) {
                isSubscribed = false;
                cancelSource.cancel(API_IS_BEING_CANCELLED);

                setFilteredValues([]);
                setLoading(false);
            }
        };

        // eslint-disable-next-line
    }, [companyIdOptions]);

    const handleDelete = (filterName: FILTER_NAMES, id: FilterId) => {
        const isSpecId = isSpecIdFilter(filterName);

        const allParams = isInnerTable
            ? innerTableQueryParams
            : filterQueryParams;

        const encodedID = isSpecId ? id : encodeURIComponent(id);

        const currentQueryValues = allParams[filterName] as string | string[];

        const currentQueryValuesArray = Array.isArray(currentQueryValues)
            ? currentQueryValues
            : [currentQueryValues];

        const updatedCurrentQueryValues = transformIdArrayWithSpecIdNotAssigned(
            filterName,
            currentQueryValuesArray
        );

        const updatedQueryValue = updateQueryValue(
            updatedCurrentQueryValues,
            encodedID
        );

        const actualFilter = findActualFilters(filteredValues, filterName);

        const updatedFilteredValues = updateFilterValues(actualFilter, id);

        const paramsPayload = {
            [filterName]: updatedQueryValue
        };

        const filteredValuesPayload = {
            filterName,
            value: updatedFilteredValues
        };

        (isInnerTable ? setInnerTableParams : setQueryParams)({
            params: paramsPayload,
            resetPage: true,
            filteredValues: filteredValuesPayload
        });
    };

    const handleClearAll = () => {
        isInnerTable ? resetInnerTableFilterParams() : resetFilterParams();
    };

    return { isLoading, filteredValues, handleDelete, handleClearAll };
};
