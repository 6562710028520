/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../../DesignComponents/TextLink";

import {
    GOOGLE_AUTHENTICATOR,
    MICROSOFT_AUTHENTICATOR
} from "../../../../../../../../shared";

const GetSecretKey = () => {
    return (
        <div
            css={css({
                display: "inline-flex",
                flexDirection: "column",

                "& > a:first-of-type": {
                    marginBottom: "4px"
                }
            })}
        >
            <TextLink
                size={TEXT_LINK_SIZES.Normal}
                href={MICROSOFT_AUTHENTICATOR}
                target="_blank"
            >
                Microsoft Authenticator
            </TextLink>

            <TextLink
                size={TEXT_LINK_SIZES.Normal}
                href={GOOGLE_AUTHENTICATOR}
                target="_blank"
            >
                Google Authenticator
            </TextLink>
        </div>
    );
};

export { GetSecretKey };
