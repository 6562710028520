/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import TextLink, { TEXT_LINK_SIZES } from "../../../DesignComponents/TextLink";

import { useTranslatedExternalLinks } from "../../../../shared";

export const CookiesMainDescription = () => {
    const { t } = useTranslation();
    const { cookiesPolicy } = useTranslatedExternalLinks();

    return (
        <p css={css({ margin: "0" })} data-testid="cookies-main-description">
            {t("Cookies##cookie banner message")}
            <TextLink
                href={cookiesPolicy}
                target="_blank"
                size={TEXT_LINK_SIZES.Normal}
                css={css({ display: "inline" })}
            >
                {t("Cookies##cookie policy")}
            </TextLink>
            .
        </p>
    );
};
