/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import GenerateToken from "./GenerateToken";
import TokenList from "./TokenList";
import GenerateTokenButtons from "./GenerateToken/GenerateTokenButtons";
import TokenListButtons from "./TokenList/TokenListButtons";
import {
    RENDER_NAMES,
    SELECTED_TOKEN_KEYS,
    SelectedToken,
    TOKEN_TYPES
} from "./types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../DesignComponents/TextLink";
import Dialog from "../../../../DesignComponents/Dialog";

import {
    DialogHandler,
    ENDPOINTS,
    INVALID_DATE,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const ApiTokens = ({ isOpen, close }: DialogHandler) => {
    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [renderName, setRenderName] = useState<RENDER_NAMES>(
        RENDER_NAMES.List
    );

    const [isLoading, setLoading] = useState(false);
    const [generatedToken, setGeneratedToken] = useState<string | null>(null);

    const [selectedToken, setSelectedToken] = useState<SelectedToken>({
        [SELECTED_TOKEN_KEYS.Type]: null,
        [SELECTED_TOKEN_KEYS.Name]: "",
        [SELECTED_TOKEN_KEYS.ExpirationDate]: null
    });

    const {
        type: selectedTokenType,
        name: selectedTokenName,
        expirationDate: selectedExpirationDate
    } = selectedToken;

    const handleSelectedTokenChange = (
        key: SELECTED_TOKEN_KEYS,
        value: TOKEN_TYPES | null | string
    ) => setSelectedToken({ ...selectedToken, [key]: value });

    const resetValue = () => {
        setGeneratedToken(null);

        setSelectedToken({
            [SELECTED_TOKEN_KEYS.Type]: null,
            [SELECTED_TOKEN_KEYS.Name]: "",
            [SELECTED_TOKEN_KEYS.ExpirationDate]: null
        });
    };

    const submitNewToken = async () => {
        try {
            setLoading(true);

            const { data } = await postData(ENDPOINTS.Tokens, {
                name: selectedTokenName,
                type: selectedTokenType,
                ...(selectedExpirationDate !== null
                    ? { expires_at: selectedExpirationDate }
                    : {})
            });

            setGeneratedToken(data);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const changeRenderName = (view: RENDER_NAMES) => setRenderName(view);

    const renderContent = () => {
        switch (renderName) {
            case RENDER_NAMES.Generate:
                return (
                    <GenerateToken
                        name={selectedTokenName}
                        token={generatedToken}
                        isLoading={isLoading}
                        change={handleSelectedTokenChange}
                    />
                );
            case RENDER_NAMES.List:
                return <TokenList />;
            default:
                return null;
        }
    };

    const generateTokenButtons = (
        <GenerateTokenButtons
            isLoading={isLoading}
            isDisabled={
                selectedToken.type === null ||
                selectedToken.name.trim().length === 0 ||
                selectedExpirationDate === INVALID_DATE
            }
            token={generatedToken}
            resetValue={resetValue}
            changeRenderName={changeRenderName}
            close={close}
        />
    );

    const renderButtons = () =>
        renderName === RENDER_NAMES.Generate ? (
            generateTokenButtons
        ) : (
            <TokenListButtons
                changeRenderName={changeRenderName}
                close={close}
            />
        );

    return (
        <Dialog
            open={isOpen}
            close={close}
            submit={submitNewToken}
            isTitleSeparator
            isActionsSeparator
            title={t("Api tokens##tokens")}
            description={
                <Trans
                    i18nKey="Api tokens##description"
                    components={{
                        1: (
                            <TextLink
                                href={`${process.env.REACT_APP_BASE_URL}documents/`}
                                target="_blank"
                                size={TEXT_LINK_SIZES.Normal}
                            >
                                here
                            </TextLink>
                        )
                    }}
                />
            }
            TransitionProps={{
                onExited: () => {
                    changeRenderName(RENDER_NAMES.List);
                    resetValue();
                }
            }}
            actions={renderButtons()}
            css={css(fromMd && maxDialogWidth)}
        >
            {renderContent()}
        </Dialog>
    );
};

export default ApiTokens;
