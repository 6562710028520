import { useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";

import ChangePasswordModal from "./ChangePasswordModal";

import ContentBox from "../ContentBox";

const ChangePassword = () => {
    const { t } = useTranslation();

    const [isOpen, setOpen] = useState(false);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    return (
        <>
            <ContentBox
                title={t("Auth##password")}
                icon={<PasswordRoundedIcon />}
                text={t("Security##password##account password")}
                margin="0 0 8px 0"
                linkText={t("Button##change")}
                linkOnClick={openDialog}
            />

            <ChangePasswordModal isOpen={isOpen} close={closeDialog} />
        </>
    );
};

export default ChangePassword;
