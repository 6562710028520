import { FilterListFieldNames, FILTER_NAMES } from "..";

export const FILTER_LIST_FIELD_NAMES: FilterListFieldNames = {
    devices: [
        FILTER_NAMES.ActivityStatus,
        FILTER_NAMES.GroupId,
        FILTER_NAMES.Model,
        FILTER_NAMES.ModelPlatform,
        FILTER_NAMES.CurrentFw,
        FILTER_NAMES.CurrentCfg,
        FILTER_NAMES.TaskQueue,
        FILTER_NAMES.SpecId
    ],
    tasks: [
        FILTER_NAMES.TaskStatus,
        FILTER_NAMES.Type,
        FILTER_NAMES.CreatedBy,
        FILTER_NAMES.GroupId,
        FILTER_NAMES.Model,
        FILTER_NAMES.CurrentFw,
        FILTER_NAMES.CurrentCfg,
        FILTER_NAMES.Id,
        FILTER_NAMES.DeviceImei,
        FILTER_NAMES.BatchId
    ],
    batches: [FILTER_NAMES.BatchTaskType],
    groups: [FILTER_NAMES.ConfigurationFileId, FILTER_NAMES.FirmwareFileId],
    files: [FILTER_NAMES.Type]
};
