/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { SendOtpCodeToDisableProps } from "../types";

import { OtpInputField } from "../../../../../../../Auth/shared";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../../DesignComponents/BannerMessage";

import { isAuthenticatorType } from "../../../../../../../../shared";

const SendOtpCodeToDisable = ({
    authType,
    error,
    isLoading,
    changeCode
}: SendOtpCodeToDisableProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <OtpInputField
                isLoading={isLoading}
                error={error}
                onChange={changeCode}
                label={
                    isAuthenticatorType(authType)
                        ? undefined
                        : t("Security##2FA##send email code##verification code")
                }
                isRequired
            />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={t(
                    `Security##2FA##disable##send otp code##no access##${authType.toLowerCase()}`
                )}
                css={css({ marginTop: "16px" })}
            />
        </div>
    );
};

export { SendOtpCodeToDisable };
