/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TransferDevicesPayloadProps } from "../../../types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../DesignComponents/InputField";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";

const TransferDevicesContent = ({
    tokenCompany,
    tokenError,
    isLoading,
    setPayload,
    clearError
}: TransferDevicesPayloadProps) => {
    const { t } = useTranslation();

    const [token, setToken] = useState("");

    useEffect(() => {
        if (token) {
            setPayload(token);
        } else {
            setPayload("");
        }
        // eslint-disable-next-line
    }, [token]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        clearError();
        setToken(e.target.value);
    };

    return (
        <>
            {!tokenCompany ? (
                <div
                    css={css({
                        "& > div:not(:first-of-type)": {
                            marginBottom: "16px"
                        }
                    })}
                >
                    <InputField
                        data-testid="device-transfer-token-field"
                        labelLeft={t("Dialog##device transfer token")}
                        fullWidth
                        placeholder={t("General##enter here")}
                        size={INPUT_FIELD_SIZES.Medium}
                        required
                        readOnly={isLoading}
                        onChange={handleInputChange}
                        errorText={tokenError}
                    />
                </div>
            ) : (
                <div>
                    <BannerMessage
                        status={BANNER_MESSAGE_STATUSES.Warning}
                        title={t("Dialog##confirm cannot revert")}
                        css={css({
                            marginBottom: "16px"
                        })}
                    />

                    <InputField
                        labelLeft={t("Table##receiving company")}
                        fullWidth
                        readOnly
                        value={tokenCompany.company_name}
                        size={INPUT_FIELD_SIZES.Medium}
                    />
                </div>
            )}
        </>
    );
};
export default TransferDevicesContent;
