/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SetStateAction, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import ResetSent from "./ResetSent";
import EnquiryText from "./EnquiryText";
import AuthContainer from "../AuthContainer";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../DesignComponents/InputField";
import { ENDPOINTS, ROUTES, useApi, useEmailValidation } from "../../../shared";

const RequestReset = () => {
    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();

    const [email, setEmail] = useState("");

    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        isEmailValid,
        emailError,
        setEmailError,
        clearEmailError,
        validateEmail
    } = useEmailValidation(email);

    const handleChange = useCallback(
        (e: { target: { value: SetStateAction<string> } }) => {
            setEmail(e.target.value);
            clearEmailError();
        },
        [clearEmailError]
    );

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (!isEmailValid) {
            setEmailError(t("Auth##invalid email format"));
        } else {
            const payload = { email };
            try {
                setLoading(true);

                await postData(ENDPOINTS.RequestResetPassword, payload);
                setLoading(false);
                setEmail("");
                handleRedirection();
            } catch (error) {
                setLoading(false);
                handleResponse(error);
            }
        }
    };

    const handleRedirection = useCallback(() => setSent(!sent), [sent]);
    const showError = isEmailValid ? "" : emailError;

    return sent ? (
        <ResetSent redirect={handleRedirection} />
    ) : (
        <AuthContainer
            formName={t("Auth##forgot your password")}
            secondaryText={t("Auth##enter email")}
        >
            <form noValidate onSubmit={handleSubmit}>
                <InputField
                    data-testid="forgot-password-input-field"
                    size={INPUT_FIELD_SIZES.Medium}
                    id="standard-error-helper-text"
                    fullWidth
                    name="email"
                    onBlur={validateEmail}
                    errorText={showError}
                    onChange={handleChange}
                    labelLeft={t("Dialog##email")}
                    placeholder={`${t("Dialog##example")} email@fota.com`}
                    readOnly={loading}
                    css={css({
                        marginBottom: "16px"
                    })}
                />

                <Button
                    data-testid="resend-password-button"
                    fullWidth
                    color={BUTTON_COLORS.Primary}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    type="submit"
                    isLoading={loading}
                >
                    {t("Auth##send reset link")}
                </Button>

                <EnquiryText
                    primaryText={t("Auth##remember password")}
                    linkText={t("Auth##sign in")}
                    component={RouterLink}
                    to={ROUTES.Login}
                />
            </form>
        </AuthContainer>
    );
};

export default RequestReset;
