declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs0: true;
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: false;
    }
}

const useMuiStyles = () => {
    return {
        typography: {
            fontFamily: '"Open Sans", sans-serif',
            fontSize: 14,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700
        },
        breakpoints: {
            values: {
                xs0: 0,
                xs: 360,
                sm: 600,
                md: 840,
                lg: 1440
            }
        },
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        fontFamily: '"Open Sans", sans-serif'
                    }
                }
            },
            MuiDialog: {
                defaultProps: {
                    closeAfterTransition: false
                }
            }
        }
    };
};

export { useMuiStyles };
