import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Value } from "../types";

import {
    DropdownValue,
    DROPDOWN_NAMES,
    EditInlineDialogProps,
    INPUT_NAMES
} from "../../../types";
import DialogActions from "../../../components/DialogActions";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";
import CompanyDropdown from "../../../../../QueryDropdown/components/CompanyDropdown";

import {
    ENDPOINTS,
    useApi,
    useMediaQueries,
    useStringInputValidation
} from "../../../../../../shared";

const EditFileInline = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();
    const { toMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);

    const [value, setValue] = useState<Value>({
        [INPUT_NAMES.DescriptionValue]: "",
        [DROPDOWN_NAMES.CompanyValue]: null
    });

    const { descriptionValue, companyValue } = value;

    const { stringInputError } = useStringInputValidation(descriptionValue);

    useEffect(() => {
        if (data) {
            const {
                description,
                company: { id: companyId, name: companyName }
            } = data;

            setValue({
                [INPUT_NAMES.DescriptionValue]: description,
                [DROPDOWN_NAMES.CompanyValue]: {
                    id: companyId,
                    name: companyName
                }
            });
        }
    }, [data]);

    const handleSubmit = async () => {
        const payload = {
            description: descriptionValue,
            company_id: companyValue?.id
        };

        try {
            setLoading(true);

            const response = await updateData(
                ENDPOINTS.Files,
                data.id,
                payload
            );

            close();
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
        setValue({ ...value, [e.target.name]: e.target.value });

    const handleDropdownChange = (
        dropdownName: DROPDOWN_NAMES,
        dropdownValue: DropdownValue | null
    ) => setValue({ ...value, [dropdownName]: dropdownValue });

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit file")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setValue({
                        [INPUT_NAMES.DescriptionValue]: "",
                        [DROPDOWN_NAMES.CompanyValue]: null
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={!stringInputError}
                    cancelButtonTestId="cancel-edit-file-inline"
                    confirmationButtonTestId="save-edit-file-inline"
                />
            }
            extendToMaxWidth={toMd}
        >
            <CompanyDropdown
                companyValue={companyValue}
                isLoading={isLoading}
                onChange={(_, val) =>
                    handleDropdownChange(DROPDOWN_NAMES.CompanyValue, val)
                }
                customStyle={{ margin: "8px 0 16px 0" }}
                isRootRequired
            />

            <InputField
                data-testid="edit-user-description-input"
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                readOnly={isLoading}
                name={INPUT_NAMES.DescriptionValue}
                value={descriptionValue}
                onChange={handleInputChange}
                labelLeft={t("Dialog##description")}
                placeholder={t("Dialog##enter description")}
                customStyle={{ marginBottom: "16px" }}
                errorText={stringInputError}
            />
        </Dialog>
    );
};

export default EditFileInline;
