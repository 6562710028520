/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";

import { SendOtpCodeToEnableProps } from "../types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../../DesignComponents/TextLink";
import { OtpInputField } from "../../../../../../../Auth/shared";

import ThemeContext from "../../../../../../../../context/theme/themeContext";
import AuthContext from "../../../../../../../../context/auth/authContext";

const SendOtpCodeToEnable = ({
    secretKey,
    error,
    isLoading,
    changeCode
}: SendOtpCodeToEnableProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const [isQr, setIsQr] = useState(true);

    const toggleIsQr = () => setIsQr(!isQr);

    return (
        <div
            css={css({
                color: textDark
            })}
        >
            <div
                css={css({
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    marginBottom: "16px"
                })}
            >
                {isQr
                    ? t("Security##2FA##send otp code##scan QR code")
                    : t("Security##2FA##send otp code##type text code")}
                :
            </div>

            {isQr ? (
                <QRCodeSVG
                    size={144}
                    fgColor={textDark}
                    value={`otpauth://totp/${process.env.REACT_APP_2FA_NAME}:${user?.email}?secret=${secretKey}&issuer=${process.env.REACT_APP_2FA_NAME}`}
                />
            ) : (
                <div
                    css={css({
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "20px",
                        letterSpacing: "0.15px"
                    })}
                >
                    {secretKey}
                </div>
            )}

            <TextLink
                size={TEXT_LINK_SIZES.Normal}
                onClick={toggleIsQr}
                css={css({
                    margin: "16px 0 24px 0"
                })}
            >
                {isQr
                    ? t("Security##2FA##send otp code##click to type code")
                    : t("Security##2FA##send otp code##click to scan code")}
                .
            </TextLink>

            <OtpInputField
                isLoading={isLoading}
                error={error}
                onChange={changeCode}
                isRequired
            />
        </div>
    );
};

export { SendOtpCodeToEnable };
