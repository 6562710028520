/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { useFilters } from "../hooks";
import FilterDropdown from "../FilterDropdown";
import FilterButton from "../components/FilterButton";
import FilterMenu from "../components/FilterMenu";

import {
    useMediaQueries,
    FILTER_NAMES,
    deviceAndGroupfiltersBlockStyle,
    filterDropdownCustomMargin,
    getEnvironment
} from "../../../shared";

export const DeviceFilters = () => {
    const { t } = useTranslation();
    const { fromLg, toLg } = useMediaQueries();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useFilters();
    const { isProductionEnv } = getEnvironment();

    return (
        <div css={css(deviceAndGroupfiltersBlockStyle)}>
            {fromLg && (
                <FilterDropdown
                    filter={FILTER_NAMES.ActivityStatus}
                    placeholder={t("Filter##activity_status")}
                    css={css([filterDropdownCustomMargin])}
                />
            )}

            <FilterButton onOpen={handleOpenMenu}>
                {fromLg ? t("Button##more filters") : t("Button##filters")}
            </FilterButton>

            <FilterMenu
                isOpen={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
            >
                <div
                    css={css({
                        "& > div + div": {
                            marginTop: "12px"
                        }
                    })}
                >
                    {toLg && (
                        <FilterDropdown
                            filter={FILTER_NAMES.ActivityStatus}
                            placeholder={t("Filter##activity_status")}
                        />
                    )}

                    <FilterDropdown
                        filter={FILTER_NAMES.GroupId}
                        placeholder={t("Filter##group_id")}
                    />

                    <FilterDropdown
                        filter={FILTER_NAMES.Model}
                        placeholder={t("Filter##model")}
                    />

                    {!isProductionEnv && (
                        <FilterDropdown
                            filter={FILTER_NAMES.ModelPlatform}
                            placeholder={t("Filter##model_platform")}
                        />
                    )}

                    <FilterDropdown
                        filter={FILTER_NAMES.CurrentFw}
                        placeholder={t("Filter##current_firmware")}
                    />

                    <FilterDropdown
                        filter={FILTER_NAMES.CurrentCfg}
                        placeholder={t("Filter##current_configuration")}
                    />

                    <FilterDropdown
                        filter={FILTER_NAMES.TaskQueue}
                        placeholder={t("Filter##task_queue")}
                    />

                    <FilterDropdown
                        filter={FILTER_NAMES.SpecId}
                        placeholder={t("Filter##spec_id")}
                    />
                </div>
            </FilterMenu>
        </div>
    );
};
