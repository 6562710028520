/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import StatusIcon from "./StatusIcon";

import { UploadedFileMultipleProps } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";

import ThemeContext from "../../../context/theme/themeContext";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import FileIcon from "../../../assets/customIcons/FileIcon";
import { FILE_UPLOAD_STATUS } from "../../../shared";

const UploadedFileMultiple = ({
    name,
    id,
    isLoading,
    fileUploadStatus,
    failedUpload,
    ...props
}: UploadedFileMultipleProps) => {
    const {
        colorsFacelift: { gray200, gray700, textPlaceholder }
    } = useContext(ThemeContext);

    const { removeFile } = useContext(DropzoneContext);

    const { t } = useTranslation();

    return (
        <div
            {...props}
            css={css({
                padding: "7px 15px",
                border: `1px solid ${gray200}`,
                borderRadius: "8px",
                "& > div": {
                    display: "flex",
                    alignItems: "center"
                },
                "&.uploaded-file-multiple-enter": {
                    opacity: "0"
                },

                "&.uploaded-file-multiple-enter-active": {
                    opacity: "1",
                    transition: "opacity 300ms ease-in"
                },

                "&.uploaded-file-multiple-exit": {
                    opacity: "1"
                },

                "&.uploaded-file-multiple-exit-active": {
                    opacity: "0",
                    transition: "opacity 150ms ease-in"
                }
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& > div": {
                        display: "flex",
                        alignItems: "center"
                    }
                })}
            >
                <div>
                    <FileIcon
                        css={css({
                            alignSelf: "flex-start",
                            marginRight: "8px",
                            color: textPlaceholder
                        })}
                    />

                    <span
                        css={css({
                            color: gray700,
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "600",
                            letterSpacing: "0.1px",
                            wordBreak: "break-word"
                        })}
                    >
                        {name}
                    </span>
                </div>
                <div
                    css={css({
                        alignSelf: "flex-start",
                        marginLeft: "8px"
                    })}
                >
                    {isLoading && (
                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "36px",
                                height: "36px",
                                marginRight: "4px"
                            })}
                        >
                            <CircularProgress size={20} />
                        </div>
                    )}

                    {fileUploadStatus === FILE_UPLOAD_STATUS.Ready ? (
                        <TooltipGeneral
                            placement={TOOLTIP_PLACEMENT.Top}
                            title={t("Button##delete")}
                            disableTouchListener
                            disableHoverListener={isLoading}
                        >
                            <Button
                                color={BUTTON_COLORS.PrimarySubtle}
                                size={BUTTON_SIZES.Small}
                                variant={BUTTON_VARIANTS.IconOnly}
                                icon={<DeleteOutlineRoundedIcon />}
                                disabled={isLoading}
                                onClick={() => removeFile(id)}
                            />
                        </TooltipGeneral>
                    ) : (
                        <StatusIcon uploadStatus={fileUploadStatus!} />
                    )}
                </div>
            </div>

            {failedUpload && (
                <div
                    css={css({
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        wordBreak: "break-word"
                    })}
                >
                    {t(`Flash##${failedUpload}`, {
                        defaultValue: failedUpload
                    })}
                </div>
            )}
        </div>
    );
};

export default UploadedFileMultiple;
