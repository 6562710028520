/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SingleValidation from "./SingleValidation";
import {
    PASSWORD_RULES,
    PasswordRules,
    PasswordValidationProps
} from "./types";

import { BUTTON_TYPES } from "../../Auth/types";

const PasswordValidation = ({
    name,
    currentPsw,
    newPsw,
    repeatPsw,
    onRulesMet
}: PasswordValidationProps) => {
    const { t } = useTranslation();

    const [isRuleMet, setIsRuleMet] = useState<PasswordRules>({
        [PASSWORD_RULES.MinimumCharacters]: false,
        [PASSWORD_RULES.Number]: false,
        [PASSWORD_RULES.LowercaseLetter]: false,
        [PASSWORD_RULES.UppercaseLetter]: false,
        [PASSWORD_RULES.SpecialSymbol]: false,
        [PASSWORD_RULES.NewPasswordsMustMatch]: false,
        [PASSWORD_RULES.NewAndCurrentPasswordsMustNotMatch]: false
    });

    const isNavBarChange = name === BUTTON_TYPES.NavBarChange;

    useEffect(() => {
        const isCharLength = newPsw.length >= 12;
        const isNumberUsed = /\d/.test(newPsw);
        const isLowercaseLetter = /[a-z]/.test(newPsw);
        const isUppercaseLetter = /[A-Z]/.test(newPsw);
        const isSpecialSymbol = /[!@#$%^&*()\-_=+]/.test(newPsw);
        const arePasswordsMatch = newPsw.length > 0 && newPsw === repeatPsw;
        const areNewAndCurrentPasswordsDifferent =
            currentPsw.length > 0 && newPsw.length > 0 && currentPsw !== newPsw;

        setIsRuleMet({
            [PASSWORD_RULES.MinimumCharacters]: isCharLength,
            [PASSWORD_RULES.Number]: isNumberUsed,
            [PASSWORD_RULES.LowercaseLetter]: isLowercaseLetter,
            [PASSWORD_RULES.UppercaseLetter]: isUppercaseLetter,
            [PASSWORD_RULES.SpecialSymbol]: isSpecialSymbol,
            [PASSWORD_RULES.NewPasswordsMustMatch]: arePasswordsMatch,
            [PASSWORD_RULES.NewAndCurrentPasswordsMustNotMatch]:
                areNewAndCurrentPasswordsDifferent
        });

        onRulesMet(
            isCharLength &&
                isNumberUsed &&
                isLowercaseLetter &&
                isUppercaseLetter &&
                isSpecialSymbol &&
                arePasswordsMatch &&
                (isNavBarChange ? areNewAndCurrentPasswordsDifferent : true)
        );

        // eslint-disable-next-line
    }, [currentPsw, newPsw, repeatPsw]);

    const getRules = () => {
        const allRules = Object.keys(isRuleMet) as PASSWORD_RULES[];

        if (isNavBarChange) {
            return allRules;
        }

        return allRules.filter(
            rule => rule !== PASSWORD_RULES.NewAndCurrentPasswordsMustNotMatch
        );
    };

    const rules = getRules();

    return (
        <div
            css={css({
                "& > div": {
                    display: "flex",
                    alignItems: "center"
                },

                "& > div + div": {
                    marginTop: "4px"
                }
            })}
        >
            {rules.map(rule => {
                const name =
                    !isNavBarChange &&
                    rule === PASSWORD_RULES.NewPasswordsMustMatch
                        ? t("Security##password##rules##passwords must match")
                        : t(`Security##password##rules##${rule}`);

                return (
                    <SingleValidation
                        key={rule}
                        isRuleMet={isRuleMet}
                        rule={rule}
                        ruleName={name}
                    />
                );
            })}
        </div>
    );
};

export default PasswordValidation;
