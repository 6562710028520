export enum TABS {
    FromForm,
    FromFile
}

export enum FIELD_NAME {
    Imei = "imei",
    Description = "description",
    Serial = "serial"
}
