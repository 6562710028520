import { useContext, useEffect, useState } from "react";

import { DropdownValue } from "../../../../types";

import ParamsContext from "../../../../../../../context/params/paramsContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    FILTER_NAMES,
    sortAndReturnArray,
    useApi,
    useIsMounted
} from "../../../../../../../shared";

export const usePackages = () => {
    const { queryParams } = useContext(ParamsContext);

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(true);
    const [packages, setPackages] = useState<DropdownValue[]>([]);

    useEffect(() => {
        const getPackages = async () => {
            try {
                const rootParams = `${FILTER_NAMES.RootCompany}=${queryParams[FILTER_NAMES.RootCompany]}`;

                const { data } = await getData(
                    `${ENDPOINTS.CanAdapters}/packages?${rootParams}`
                );

                const sortedData = sortAndReturnArray(
                    data as DropdownValue[],
                    "name"
                );

                if (isMounted) {
                    setPackages(sortedData);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                    setLoading(false);
                }
            }
        };

        getPackages();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };
        // eslint-disable-next-line
    }, []);

    return { isLoading, packages };
};
