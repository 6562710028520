import { useTranslation } from "react-i18next";

import { ChangePayloadProps, Feature, FEATURES } from "./types";

import Autocomplete from "../../../../../../DesignComponents/Autocomplete";
import { DROPDOWN_SIZES } from "../../../../../../../shared";

const FeatureControl = ({ isLoading, changePayload }: ChangePayloadProps) => {
    const { t } = useTranslation();

    const features: Feature[] = [{ type: FEATURES.Obd, name: "obd oem" }];

    const handleChange = (value: Feature) => {
        const payload =
            value !== null
                ? {
                      id: "attributes",
                      value: JSON.stringify({ feature: value.type })
                  }
                : null;

        changePayload(payload);
    };

    return (
        <Autocomplete
            fullWidth
            isRequired
            size={DROPDOWN_SIZES.Medium}
            labelLeft="Feature"
            textFieldParams={{
                placeholder: "Select"
            }}
            options={features}
            getOptionLabel={option => t(`Dialog##${option.name}`)}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            readOnly={isLoading}
            onChange={(_, featureType) => handleChange(featureType)}
        />
    );
};

export default FeatureControl;
