import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Value } from "../types";

import CanPackageDropdown from "../../components/CanPackageDropdown";

import DialogActions from "../../../components/DialogActions";
import {
    DROPDOWN_NAMES,
    DropdownValue,
    EditInlineDialogProps,
    INPUT_NAMES
} from "../../../types";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";

import { ENDPOINTS, useApi, useMediaQueries } from "../../../../../../shared";

const EditProductCode = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { t } = useTranslation();
    const { toMd } = useMediaQueries();
    const { handleResponse, updateData } = useApi();

    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState<Value>({
        [INPUT_NAMES.ProductCodeValue]: "",
        [DROPDOWN_NAMES.CanPackageValue]: null
    });

    useEffect(() => {
        if (data) {
            const { product_code, can_package } = data;

            setValue({
                [INPUT_NAMES.ProductCodeValue]: product_code,
                [DROPDOWN_NAMES.CanPackageValue]: can_package
            });
        }
    }, [data]);

    const { productCodeValue, canPackageValue } = value;

    const handleDropdownChange = (option: DropdownValue | null) =>
        setValue({ ...value, [DROPDOWN_NAMES.CanPackageValue]: option });

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const payload = {
                can_package_id: canPackageValue?.id || null
            };

            const response = await updateData(
                ENDPOINTS.ProductCodes,
                data.id,
                payload
            );

            close();
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit product code")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setValue({
                        [INPUT_NAMES.ProductCodeValue]: "",
                        [DROPDOWN_NAMES.CanPackageValue]: null
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={true}
                />
            }
            extendToMaxWidth={toMd}
        >
            <InputField
                data-testid="edit-product-code-input"
                fullWidth
                readOnly
                size={INPUT_FIELD_SIZES.Medium}
                value={productCodeValue}
                labelLeft={t("Table##product code")}
                customStyle={{ margin: "8px 0 16px 0" }}
            />

            <CanPackageDropdown
                isSubmitLoading={isLoading}
                change={handleDropdownChange}
                value={canPackageValue}
                customStyle={{ marginBottom: "16px" }}
            />
        </Dialog>
    );
};

export default EditProductCode;
