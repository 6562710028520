import { useEffect, useState } from "react";

import Vehicles from "./Vehicles";

import { ChangePayloadProps } from "../types";

const CanVehicles = ({ isLoading, changePayload }: ChangePayloadProps) => {
    const [modelId, setModelId] = useState<number | null>(null);

    useEffect(() => {
        if (modelId !== null) {
            changePayload({
                id: "attributes",
                value: JSON.stringify({ vehicle_id: modelId })
            });
        } else {
            changePayload(null);
        }
        // eslint-disable-next-line
    }, [modelId]);

    const handleModelId = (id: number | null) => setModelId(id);

    return (
        <Vehicles isSubmitLoading={isLoading} changeModelId={handleModelId} />
    );
};

export default CanVehicles;
