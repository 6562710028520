import { SyntheticEvent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";

import AddDeviceFromFile from "./AddDeviceFromFile/AddDeviceFromFile";
import AddDeviceFromForm from "./AddDeviceFromForm/AddDeviceFromForm";
import { AddDevicePayload, TABS } from "./types";

import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import SegmentedToggle, {
    TAB_SIZES
} from "../../../../DesignComponents/SegmentedToggle";

import ParamsContext from "../../../../../context/params/paramsContext";
import DropzoneContext from "../../../../../context/dropzone/dropzoneContext";
import FromFormIcon from "../../../../../assets/customIcons/actions/FromFormIcon";
import { TABLE_NAMES, useApi, useMediaQueries } from "../../../../../shared";

const AddDevice = ({ isOpen, close }: DialogProps) => {
    const { setReloadItems } = useContext(ParamsContext);
    const { removeAllFiles } = useContext(DropzoneContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromSm } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(TABS.FromForm);

    const [payloadInfo, setPayloadInfo] = useState<AddDevicePayload | null>(
        null
    );

    const handleChanges = (data: AddDevicePayload) =>
        data !== null ? setPayloadInfo(data) : setPayloadInfo(null);

    const handleCloseDialog = useCallback(() => {
        close(DEVICE_ACTIONS.AddDevice);
    }, [close]);

    const handleTabChange = (_: SyntheticEvent, newValue: TABS) => {
        setTabValue(newValue);
        setPayloadInfo(null);
        tabValue === 1 && removeAllFiles();
    };

    const handleSubmit = useCallback(async () => {
        if (payloadInfo) {
            const { resource, payload } = payloadInfo;

            setLoading(true);

            try {
                const response = await postData(resource, payload);

                handleCloseDialog();
                setReloadItems(TABLE_NAMES.Devices);
                handleResponse(response);
            } catch (error) {
                handleResponse(error);
            }
        }
        setLoading(false);
    }, [
        payloadInfo,
        handleCloseDialog,
        handleResponse,
        postData,
        setReloadItems
    ]);

    return (
        <Dialog
            data-testid="add-device-data-dialog"
            extendToMaxWidth
            title={t("Dialog##add new device")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setTabValue(TABS.FromForm);
                    removeAllFiles();
                    setPayloadInfo(null);
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={payloadInfo !== null}
                    confirmationButtonText={t("Button##add")}
                    cancelButtonTestId="cancel-add-device"
                    confirmationButtonTestId="add-device-button"
                />
            }
        >
            <SegmentedToggle
                data-testid="add-device-tabs"
                value={tabValue}
                size={TAB_SIZES.Small}
                fullWidth={fromSm}
                tabWidth="277px"
                onChange={handleTabChange}
                customStyle={{
                    marginTop: "8px"
                }}
            >
                <Tab
                    data-testid="add-device-form-tab"
                    label={t("Dialog##from form")}
                    icon={<FromFormIcon />}
                />

                <Tab
                    data-testid="add-device-file-tab"
                    label={t("Dialog##from file")}
                    icon={<FileUploadIcon />}
                />
            </SegmentedToggle>

            {tabValue === TABS.FromForm && (
                <AddDeviceFromForm
                    isLoading={isLoading}
                    setDevicePayload={handleChanges}
                />
            )}

            {tabValue === TABS.FromFile && (
                <AddDeviceFromFile
                    isLoading={isLoading}
                    setDevicePayload={handleChanges}
                />
            )}
        </Dialog>
    );
};

export default AddDevice;
