import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FileType } from "../types";

import AuthContext from "../../../../../context/auth/authContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import {
    FILE_TYPES,
    isInternalTypeCompany,
    moveCarrierToBottom,
    sortAndReturnArray
} from "../../../../../shared";

const useFileTypes = () => {
    const { isAdminUser, isAdminManagerUser, isCarrierUser } =
        useContext(AuthContext);

    const { rootCompanyType } = useContext(ParamsContext);

    const { t } = useTranslation();

    const types: FileType[] = [
        // ADAS firmware
        {
            value: FILE_TYPES.AdasFw,
            extensions: [".zip"],
            isAllowedToRender: isAdminUser
        },

        // BLE firmware
        {
            value: FILE_TYPES.BleFw,
            extensions: [".bin"],
            isAllowedToRender: true
        },

        // BlueNRG firmware
        {
            value: FILE_TYPES.BlueNrg,
            extensions: [".bin"],
            isAllowedToRender: true
        },

        // Certificate
        {
            value: FILE_TYPES.Certificate,
            extensions: [".pem"],
            isAllowedToRender: true
        },

        // Configuration
        {
            value: FILE_TYPES.Configuration,
            extensions: [".cfg"],
            isAllowedToRender: true
        },

        // DSM firmware
        {
            value: FILE_TYPES.DsmFw,
            extensions: [".zip"],
            isAllowedToRender: isAdminUser
        },

        // DTB
        {
            value: FILE_TYPES.Dtb,
            extensions: [".e.xim"],
            isAllowedToRender: isAdminManagerUser
        },

        // Firmware
        {
            value: FILE_TYPES.Firmware,
            extensions: [".e.xim", ".e.cfw"],
            isAllowedToRender: true
        },

        // Firmware bundle
        {
            value: FILE_TYPES.FirmwareBundle,
            extensions: [".tpack"],
            isAllowedToRender:
                isAdminManagerUser && isInternalTypeCompany(rootCompanyType)
        },

        // FM CAN Chip firmware
        {
            value: FILE_TYPES.CanChipFw,
            extensions: [".fwc"],
            isAllowedToRender: isAdminUser
        },

        // FM OBD Chip firmware
        {
            value: FILE_TYPES.FmObdChipFw,
            extensions: [".fwc"],
            isAllowedToRender: isAdminManagerUser
        },

        // GNSS-M Download Assistant
        {
            value: FILE_TYPES.GnssmDownloadAssistant,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },

        // GNSS-M firmware
        {
            value: FILE_TYPES.GnssmFw,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },

        // MeiG modem firmware
        {
            value: FILE_TYPES.MeiGModemFw,
            extensions: [".pack"],
            isAllowedToRender: isAdminUser
        },

        // Quectel modem firmware
        {
            value: FILE_TYPES.QuectelModemFw,
            extensions: [".bin", ".pack", ".zip"],
            isAllowedToRender: isAdminUser
        },

        // Subscription file
        {
            value: FILE_TYPES.Psub,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },

        // Carrier Advance firmware
        {
            value: FILE_TYPES.CarrierAdvanceFw,
            extensions: [".hex", ".bex"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },

        // Carrier Advance settings
        {
            value: FILE_TYPES.CarrierAdvanceSettings,
            extensions: [".set"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },

        // Carrier APX firmware
        {
            value: FILE_TYPES.CarrierApxFw,
            extensions: [".tar"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },

        // Carrier APX settings
        {
            value: FILE_TYPES.CarrierApxSettings,
            extensions: [".set"],
            isAllowedToRender: isAdminUser || isCarrierUser
        }
    ];

    const typesWithTranslations = types.map(type => {
        return {
            ...type,
            translatedName: t(`General##${type.value}`)
        };
    });

    const sortedTypes = sortAndReturnArray(
        typesWithTranslations,
        "translatedName"
    );

    moveCarrierToBottom(sortedTypes);

    const visibleTypes = sortedTypes.filter(type => type.isAllowedToRender);

    return { visibleTypes };
};

export default useFileTypes;
