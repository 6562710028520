/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { GeneralDropdownProps } from "./types";

import UploadFile from "../../../../Files/UploadFile/UploadFile";

import { useDialog } from "../../../../../ActionBar/hooks";
import { FILE_ACTIONS } from "../../../../../types";

import QueryDropdown from "../../../../../../QueryDropdown";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";
import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../DesignComponents/TextLink";

import {
    COLUMN_RESOURCES,
    DROPDOWN_SIZES,
    ENDPOINTS,
    FILE_TYPES,
    ID_TYPE,
    Query,
    TASK_TYPES
} from "../../../../../../../shared";

const GeneralDropdown = ({
    secondSelectName,
    isLoading,
    taskType,
    changePayload
}: GeneralDropdownProps) => {
    const { t } = useTranslation();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const getTooltipTitle = useCallback(() => {
        const tooltipTitleMain = t(
            `Dialog##no dropdown files##${secondSelectName}##main`
        );

        const tooltipTitleDescription = t(
            `Dialog##no dropdown files##${secondSelectName}##description`
        );

        return `${tooltipTitleMain} ${tooltipTitleDescription}`;
    }, [secondSelectName, t]);

    const [tooltipTitle, setTooltipTitle] = useState(getTooltipTitle());
    const [hasUploadedFile, setUploadedFile] = useState(false);
    const [fileAttributes, setFileAttributes] = useState<string | null>(null);

    useEffect(() => {
        setTooltipTitle(getTooltipTitle());
    }, [getTooltipTitle]);

    const handleChange = (value: Query | null) => {
        const isUploadFirmwareTask = taskType === TASK_TYPES.TxFirmware;

        isUploadFirmwareTask && setFileAttributes(value?.attributes || null);

        const payload =
            value !== null ? { id: "file_id", value: value.id } : null;

        changePayload(payload);
    };

    const renderBannerMsg = () => {
        if (fileAttributes) {
            try {
                const parsedData = JSON.parse(fileAttributes);
                const firmwareVersion = parsedData?.version;

                if (firmwareVersion) {
                    const majorVersion = Number(firmwareVersion.split(".")[0]);
                    return majorVersion >= 4 && majorVersion < 9;
                }
            } catch (error) {
                return false;
            }
        }

        return false;
    };

    return (
        <>
            <QueryDropdown
                data-testid="second-select-dropdown"
                // key prop resets default value when key value changes
                key={secondSelectName}
                fullWidth
                isRequired
                noOptionsTooltip={{
                    title: tooltipTitle,
                    fileType: secondSelectName as FILE_TYPES,
                    hasUploadedFile,
                    buttonText: t("Button##upload file"),
                    onButtonClick: () => openDialog(FILE_ACTIONS.UploadFiles)
                }}
                idType={ID_TYPE.Id}
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t(`General##${secondSelectName}`)}
                readOnly={isLoading}
                descriptionResource={[
                    COLUMN_RESOURCES.Company,
                    COLUMN_RESOURCES.Name
                ]}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                resource={`${ENDPOINTS.Files}?type=${secondSelectName}`}
                change={(_, fileValue) => handleChange(fileValue)}
            />

            <UploadFile
                isOpen={isOpenDialog[FILE_ACTIONS.UploadFiles]}
                close={closeDialog}
                onEnter={() => setTooltipTitle("")}
                onExited={() => setTooltipTitle(getTooltipTitle())}
                successCallback={() => setUploadedFile(!hasUploadedFile)}
            />

            {renderBannerMsg() && (
                <BannerMessage
                    status={BANNER_MESSAGE_STATUSES.Warning}
                    title={
                        <Trans
                            i18nKey="Dialog##upload firmware info"
                            components={{
                                1: (
                                    <TextLink
                                        href="https://wiki.teltonika-gps.com/view/FMB_firmware_errata_04.00.00"
                                        size={TEXT_LINK_SIZES.Small}
                                        target="_blank"
                                    >
                                        Teltonika GPS Wiki
                                    </TextLink>
                                )
                            }}
                        />
                    }
                    css={css({
                        marginTop: "16px"
                    })}
                />
            )}
        </>
    );
};

export default GeneralDropdown;
