/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { InfoComponentProps } from "./types";

import ThemeContext from "../../../../context/theme/themeContext";

const InfoComponent = ({
    title,
    isDisabled,
    customStyle
}: InfoComponentProps) => {
    const {
        colorsFacelift: { gray700, textDarkDisabled }
    } = useContext(ThemeContext);

    return (
        <span
            css={css({
                color: isDisabled ? textDarkDisabled : gray700,
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "0.4px",
                marginBottom: "2px",
                ...customStyle
            })}
        >
            {title}
        </span>
    );
};

export { InfoComponent };
