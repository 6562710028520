import { useReducer, useMemo } from "react";

import RoutesContext from "./routesContext";
import RoutesReducer from "./routesReducer";
import {
    TOGGLE_STATISTICS,
    CLOSE_STATISTICS,
    SET_DESKTOP_DRAWER_STATUS,
    SET_MOBILE_DRAWER_STATUS,
    TOGGLE_COMPANY_LEVEL_DIALOG_OPEN
} from "./routesActions";
import { RoutesStateProps } from "./types";

import {
    DESKTOP_DRAWER_OPEN,
    IS_DEVICE_STATISTICS_OPEN,
    useMediaQueries
} from "../../shared";

const RoutesState = ({ children }: RoutesStateProps) => {
    const { to1099 } = useMediaQueries();

    const initialState = {
        isStatisticsOpen:
            localStorage.getItem(IS_DEVICE_STATISTICS_OPEN) === "true",
        isDesktopDrawerOpen:
            !to1099 && localStorage.getItem(DESKTOP_DRAWER_OPEN) !== "false",
        isMobileDrawerOpen: false,
        isMaintenanceBannerOpen: true,
        isCompanyLevelDialogOpen: false,
        isCompanyLevelDialogScrollToFaq: false
    };

    const [state, dispatch] = useReducer(RoutesReducer, initialState);

    const toggleStatistics = () => dispatch({ type: TOGGLE_STATISTICS });
    const closeStatistics = () => dispatch({ type: CLOSE_STATISTICS });

    const setDesktopDrawerStatus = (isOpenDesktop: boolean) => {
        dispatch({
            type: SET_DESKTOP_DRAWER_STATUS,
            payload: isOpenDesktop
        });
    };

    const setMobileDrawerStatus = (isOpenMobile: boolean) => {
        dispatch({
            type: SET_MOBILE_DRAWER_STATUS,
            payload: isOpenMobile
        });
    };

    const toggleCompanyLevelDialogOpen = (isOpen: boolean, isScroll: boolean) =>
        dispatch({
            type: TOGGLE_COMPANY_LEVEL_DIALOG_OPEN,
            payload: { isOpen, isScroll }
        });

    const value = useMemo(
        () => ({
            isStatisticsOpen: state.isStatisticsOpen,
            isDesktopDrawerOpen: state.isDesktopDrawerOpen,
            isMobileDrawerOpen: state.isMobileDrawerOpen,
            isMaintenanceBannerOpen: state.isMaintenanceBannerOpen,
            isCompanyLevelDialogOpen: state.isCompanyLevelDialogOpen,
            isCompanyLevelDialogScrollToFaq:
                state.isCompanyLevelDialogScrollToFaq,
            toggleStatistics,
            closeStatistics,
            setDesktopDrawerStatus,
            setMobileDrawerStatus,
            toggleCompanyLevelDialogOpen
        }),
        [
            state.isStatisticsOpen,
            state.isDesktopDrawerOpen,
            state.isMobileDrawerOpen,
            state.isMaintenanceBannerOpen,
            state.isCompanyLevelDialogOpen,
            state.isCompanyLevelDialogScrollToFaq
        ]
    );

    return (
        <RoutesContext.Provider value={value}>
            {children}
        </RoutesContext.Provider>
    );
};

export default RoutesState;
