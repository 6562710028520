/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";

import { TELTONIKA_TELEMATICS_WEBSITE_CONTACTS } from "../../../shared";

const ContactUs = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <span
                css={css({
                    marginRight: "5px"
                })}
            >
                {t("Auth##do not have account")}
            </span>

            <TextLink
                size={TEXT_LINK_SIZES.Large}
                href={TELTONIKA_TELEMATICS_WEBSITE_CONTACTS}
                target="_blank"
            >
                {t("Auth##contact us")}
            </TextLink>
        </Fragment>
    );
};

export default ContactUs;
