import { useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";

import Company from "./Company";
import Group from "./Group";
import Description from "./Description";
import GeneralReducer from "./generalReducer";
import { CHANGE_VALUE, CHANGE_EDITABLE } from "./generalActions";
import { ChangedValue, InputName } from "./types";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import TableContext from "../../../../../context/table/tableContext";

const General = () => {
    const { detailsData } = useContext(TableContext);

    const {
        company_id,
        company_name,
        group_id,
        group_name,
        description,
        serial,
        current_firmware,
        dtb_version,
        ble_firmware,
        current_configuration,
        spec_id,
        gnss_version,
        bootloader_version,
        product_code
    } = detailsData;

    const { t } = useTranslation();

    const initialState = {
        isEditable: {
            company: false,
            group: false,
            description: false
        },
        value: {
            company: null,
            group: null,
            description: ""
        }
    };

    const [state, dispatch] = useReducer(GeneralReducer, initialState);

    const { isEditable, value } = state;

    const changeEditable = (name: InputName, inputValue: boolean) =>
        dispatch({
            type: CHANGE_EDITABLE,
            payload: {
                name,
                value: inputValue,
                description: description || "",
                company_id,
                company_name,
                group_id,
                group_name
            }
        });

    const changeValue = (
        name: InputName,
        newValue: ChangedValue,
        isUpdate?: boolean
    ) =>
        dispatch({
            type: CHANGE_VALUE,
            payload: { name, value: newValue, isUpdate: Boolean(isUpdate) }
        });

    return (
        <>
            <div>{t("Table##general")}</div>

            <div>
                <Company
                    isEditable={isEditable.company}
                    value={value.company}
                    toggleEditable={changeEditable}
                    changeValue={changeValue}
                />

                <Group
                    isEditable={isEditable.group}
                    value={value.group}
                    toggleEditable={changeEditable}
                    changeValue={changeValue}
                />

                <Description
                    isEditable={isEditable.description}
                    value={value.description}
                    toggleEditable={changeEditable}
                    changeValue={changeValue}
                />

                {/* Serial number */}
                {serial !== null && (
                    <>
                        <span>{t("Table##serial")}:</span>

                        <span>
                            {String(serial)}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={String(serial)}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* Current firmware */}
                {current_firmware && (
                    <>
                        <span>{t("Table##firmware")}:</span>

                        <span>
                            {current_firmware}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={current_firmware}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* DTB version */}
                {dtb_version && (
                    <>
                        <span>{t("General##dtb")}:</span>

                        <span>
                            {dtb_version}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={dtb_version}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* BLE firmware */}
                {ble_firmware && (
                    <>
                        <span>{t("General##ble_fw")}:</span>

                        <span>
                            {ble_firmware}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={ble_firmware}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* Configuration */}
                {current_configuration && (
                    <>
                        <span>{t("Table##configuration")}:</span>

                        <span>
                            {current_configuration}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={current_configuration}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* Spec. ID */}
                {spec_id > 1 && (
                    <>
                        <span>{t("Table##spec_id")}:</span>

                        <span>
                            {String(spec_id)}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={String(spec_id)}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* GNSS version */}
                {gnss_version && (
                    <>
                        <span>{t("Table##gnss version")}:</span>

                        <span>
                            {gnss_version}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={gnss_version}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* Bootloader */}
                {bootloader_version && (
                    <>
                        <span>{t("Table##bootloader")}:</span>

                        <span>
                            {bootloader_version}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={bootloader_version}
                            />
                        </span>
                    </>
                )}
                {/* */}

                {/* Product code */}
                {product_code && (
                    <>
                        <span>{t("Table##product code")}:</span>

                        <span>
                            {product_code}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={product_code}
                            />
                        </span>
                    </>
                )}
                {/* */}
            </div>
        </>
    );
};

export default General;
