import { useContext } from "react";

import { Task } from "../types";
import { GROUPS } from "../CreateTaskContent/types";
import { CUSTOM_SECOND_SELECT } from "../CreateTaskContent/SecondSelectContent/types";

import AuthContext from "../../../../../../context/auth/authContext";
import { FILE_TYPES, TASK_TYPES } from "../../../../../../shared";

const useTaskTypes = () => {
    const { isAdminUser, isCarrierUser, isSupportUser } =
        useContext(AuthContext);

    const tasks: Task[] = [
        // Upload firmware
        {
            type: TASK_TYPES.TxFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.Firmware
        },

        // Upload configuration
        {
            type: TASK_TYPES.TxConfiguration,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.Configuration
        },

        // Receive configuration
        {
            type: TASK_TYPES.RxConfiguration,
            group: GROUPS.General
        },

        // Upload user TLS certificate
        {
            type: TASK_TYPES.TxUserCertificate,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.Certificate
        },

        // Upload BlueNRG firmware
        {
            type: TASK_TYPES.TxBlueNrgFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.BlueNrg
        },

        // Upload Ble firmware
        {
            type: TASK_TYPES.TxBleFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.BleFw
        },

        // Upload ADAS firmware
        {
            type: TASK_TYPES.TxAdasFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.AdasFw,
            isRestricted: !isAdminUser
        },

        // Upload DSM firmware
        {
            type: TASK_TYPES.TxDsmFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.DsmFw,
            isRestricted: !isAdminUser
        },

        // Transfer subscription file
        {
            type: TASK_TYPES.TxPSub,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.Psub,
            isRestricted: !isAdminUser
        },

        // Upload GNSS-M Download Assistant
        {
            type: TASK_TYPES.TxGNSSMDownloadAssistant,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.GnssmDownloadAssistant,
            isRestricted: !isAdminUser
        },

        // Upload GNSS-M firmware
        {
            type: TASK_TYPES.TxGNSSMFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.GnssmFw,
            isRestricted: !isAdminUser
        },

        // Upload MeiG Delta Modem Firmware
        {
            type: TASK_TYPES.TxMeigModemDeltaFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.MeiGModemFw,
            isRestricted: !isAdminUser
        },

        // Upload Quectel Delta Modem Firmware
        {
            type: TASK_TYPES.TxQuectelModemDeltaFirmware,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.QuectelModemFw,
            isRestricted: !isAdminUser
        },

        // Upload DTB
        {
            type: TASK_TYPES.TxDtb,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.Dtb,
            isRestricted: !isAdminUser
        },

        // Upload Firmware bundle
        {
            type: TASK_TYPES.TxFirmwareBundle,
            group: GROUPS.General,
            secondSelect: FILE_TYPES.FirmwareBundle,
            isRestricted: !isSupportUser
        },

        // Upload BLE Firmware - part of TxFirmwareBundle
        {
            type: TASK_TYPES.TxBundleBle,
            group: GROUPS.General,
            isRestricted: true
        },

        // Upload CAN Firmware - part of TxFirmwareBundle
        {
            type: TASK_TYPES.TxBundleCan,
            group: GROUPS.General,
            isRestricted: true
        },

        // Upload Main DTB - part of TxFirmwareBundle
        {
            type: TASK_TYPES.TxBundleDtb,
            group: GROUPS.General,
            isRestricted: true
        },

        // Upload Main Firmware - part of TxFirmwareBundle
        {
            type: TASK_TYPES.TxBundleFirmware,
            group: GROUPS.General,
            isRestricted: true
        },

        // Receive watch-dog (WD) log
        {
            type: TASK_TYPES.RxWdLog,
            group: GROUPS.LogFiles,
            isRestricted: !isAdminUser
        },

        // Receive internal log
        {
            type: TASK_TYPES.RxLog,
            group: GROUPS.LogFiles,
            secondSelect: CUSTOM_SECOND_SELECT.Logs
        },

        // Receive black box
        {
            type: TASK_TYPES.RxBlackBox,
            group: GROUPS.LogFiles,
            isRestricted: !isAdminUser
        },

        // Upload CAN adapter firmware
        {
            type: TASK_TYPES.TxCanAdapterUpdate,
            group: GROUPS.CanObd,
            ...(isAdminUser
                ? { secondSelect: CUSTOM_SECOND_SELECT.CanAdapters }
                : {})
        },

        // Change external CAN adapter mode
        {
            type: TASK_TYPES.TxCanAdapterModeChange,
            group: GROUPS.CanObd,
            secondSelect: CUSTOM_SECOND_SELECT.CanAdapters,
            isRestricted: !isAdminUser
        },

        // Change internal CAN adapter mode
        {
            type: TASK_TYPES.TxCanAdapterModeChangeInternal,
            group: GROUPS.CanObd,
            secondSelect: CUSTOM_SECOND_SELECT.CanAdapters,
            isRestricted: !isAdminUser
        },

        // Upload FMXY50 CAN chip firmware
        {
            type: TASK_TYPES.TxCanChipFirmware,
            group: GROUPS.CanObd,
            secondSelect: FILE_TYPES.CanChipFw
        },

        // Upload CAN OEM file
        {
            type: TASK_TYPES.TxCanConfiguration,
            group: GROUPS.CanObd,
            secondSelect: CUSTOM_SECOND_SELECT.CanVehicles
        },

        // Enable feature
        {
            type: TASK_TYPES.SetEnableFeature,
            group: GROUPS.FeatureControl,
            secondSelect: CUSTOM_SECOND_SELECT.FeatureControl,
            isRestricted: !isAdminUser
        },

        // Disable feature
        {
            type: TASK_TYPES.SetDisableFeature,
            group: GROUPS.FeatureControl,
            secondSelect: CUSTOM_SECOND_SELECT.FeatureControl,
            isRestricted: !isAdminUser
        },

        // Bind device with current SIM card(s)
        {
            type: TASK_TYPES.SetBindSim,
            group: GROUPS.FeatureControl,
            isRestricted: !isAdminUser
        },

        // Unbind device with SIM card(s)
        {
            type: TASK_TYPES.SetUnbindSim,
            group: GROUPS.FeatureControl,
            isRestricted: !isAdminUser
        },

        // Force SIM lock
        {
            type: TASK_TYPES.SetForceLockSim,
            group: GROUPS.FeatureControl,
            isRestricted: !isAdminUser
        },

        // Update Carrier Apx firmware
        {
            type: TASK_TYPES.TxCarrierApxFirmware,
            group: GROUPS.Carrier,
            secondSelect: FILE_TYPES.CarrierApxFw,
            isRestricted: !isAdminUser && !isCarrierUser
        },

        // Update Carrier Advance firmware
        {
            type: TASK_TYPES.TxCarrierAdvanceFirmware,
            group: GROUPS.Carrier,
            secondSelect: FILE_TYPES.CarrierAdvanceFw,
            isRestricted: !isAdminUser && !isCarrierUser
        },

        // Update Carrier Apx settings
        {
            type: TASK_TYPES.TxCarrierApxSettings,
            group: GROUPS.Carrier,
            secondSelect: FILE_TYPES.CarrierApxSettings,
            isRestricted: !isAdminUser && !isCarrierUser
        },

        // Update Carrier Advance settings
        {
            type: TASK_TYPES.TxCarrierAdvanceSettings,
            group: GROUPS.Carrier,
            secondSelect: FILE_TYPES.CarrierAdvanceSettings,
            isRestricted: !isAdminUser && !isCarrierUser
        },

        // Download Carrier extractions
        {
            type: TASK_TYPES.RxCarrierExtractions,
            group: GROUPS.Carrier,
            isRestricted: !isAdminUser && !isCarrierUser
        },

        // Download Carrier temperature logger files
        {
            type: TASK_TYPES.RxCarrierLoggerFiles,
            group: GROUPS.Carrier,
            isRestricted: !isAdminUser && !isCarrierUser
        }
    ];

    const visibleTasks = tasks.filter(task => !task.isRestricted);

    return { tasks, visibleTasks };
};

export default useTaskTypes;
