import { SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthContext from "../../../../context/auth/authContext";

export const useTwoFactorVerification = () => {
    const { authType, loginUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const [code, setCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleChange = (newValue: string) => {
        setError(null);
        setCode(newValue);
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        loginUser({
            payload: {
                otp_code: code,
                two_factor_auth_type: authType!
            },
            is2Fa: true,
            badRequestCallback: () =>
                setError(t("Security##2FA##send otp code##incorrect code"))
        });
    };

    return {
        otpCode: code,
        twoFaError: error,
        handleOtpChange: handleChange,
        handle2FaSubmit: handleSubmit
    };
};
