import { useContext } from "react";

import { isLithuanianLanguage } from "../functions";

import { TELTONIKA_IOT_GROUP_WEBSITE } from "../constants";

import LanguageContext from "../../context/language/languageContext";

export const useTranslatedExternalLinks = () => {
    const { language } = useContext(LanguageContext);

    const isLtLanguage = isLithuanianLanguage(language);

    const POLICIES_CERTIFICATE_PATH_EN = "policies-certificates/";
    const POLICIES_CERTIFICATE_PATH_LT = "lt/politika-ir-sertifikatai/";

    const directory = isLtLanguage
        ? POLICIES_CERTIFICATE_PATH_LT
        : POLICIES_CERTIFICATE_PATH_EN;

    const cookiesPolicyResource = isLtLanguage
        ? "slapuku-politika"
        : "cookies-policy";

    const dataProcessingAgreementResource = isLtLanguage
        ? "duomenu-tvarkymo-sutartis"
        : "data-proccesing-agreement";

    const privacyPolicyResource = isLtLanguage
        ? "privatumo-politika"
        : "privacy-policy";

    const termsOfServiceResource = isLtLanguage
        ? "paslaugu-teikimo-salygos"
        : "teltonika-terms-of-service";

    const url = `${TELTONIKA_IOT_GROUP_WEBSITE}${directory}`;

    const cookiesPolicy = `${url}${cookiesPolicyResource}`;
    const dataProcessingAgreement = `${url}${dataProcessingAgreementResource}`;
    const privacyPolicy = `${url}${privacyPolicyResource}`;
    const termsOfService = `${url}${termsOfServiceResource}`;

    return {
        cookiesPolicy,
        dataProcessingAgreement,
        privacyPolicy,
        termsOfService
    };
};
