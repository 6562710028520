/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useUpdateDesktopLicense } from "../../shared/hooks";

import DialogActions from "../../../components/DialogActions";

import { DialogProps, LICENSE_ACTIONS } from "../../../../types";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";
import DatePicker from "../../../../../DatePicker";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../context/alert/alertContext";
import TableContext from "../../../../../../context/table/tableContext";
import {
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries,
    useStringInputValidation
} from "../../../../../../shared";

const CreateOfflineLicense = ({ isOpen, close }: DialogProps) => {
    const { setAlert } = useContext(AlertContext);
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { postData, handleResponse } = useApi();
    const { updateLicenseDetails } = useUpdateDesktopLicense();

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");

    const { stringInputError: nameError } = useStringInputValidation(name);

    const {
        id: licenseId,
        name: licenseName,
        company,
        expires_at
    } = detailsData;

    const handleCloseDialog = () => close(LICENSE_ACTIONS.CreateDesktopLicense);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const payload = {
                name,
                license_id: licenseId
            };

            const {
                data: { name: offlineLicenseName }
            } = await postData(ENDPOINTS.OfflineLicenses, payload);

            handleCloseDialog();

            updateLicenseDetails(licenseId);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##offline license added", {
                    name: offlineLicenseName
                })
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            title={t("Dialog##create offline license")}
            description="Description text"
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => setName("")
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={name.trim() && !nameError}
                    confirmationButtonText={t("Button##create")}
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            <div
                css={css({
                    "& > div + div": {
                        marginTop: "16px"
                    },

                    marginBottom: "16px"
                })}
            >
                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    required
                    onChange={e => setName(e.target.value)}
                    labelLeft={t("Table##license name")}
                    placeholder={`${t("Dialog##example")} Offline license 1`}
                    value={name}
                    readOnly={isLoading}
                    errorText={nameError}
                />

                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    labelLeft={t("Dialog##desktop license name")}
                    value={licenseName}
                    readOnly
                />

                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    labelLeft={t("Dialog##company")}
                    value={company.name}
                    readOnly
                />

                <DatePicker
                    label={t("Dialog##expires at")}
                    value={moment.utc(expires_at)}
                    readOnly
                />
            </div>
        </Dialog>
    );
};

export default CreateOfflineLicense;
