import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { useApi } from "./useApi";

import { sendCodeToEmail } from "../functions";
import {
    AUTHENTICATION_TYPES,
    ENABLE_AUTHENTICATION_STEPS,
    ENDPOINTS
} from "../types";

import AlertContext, { ALERT_STATUS } from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";

const useEnableTwoFactorAuthentication = () => {
    const { setAlert } = useContext(AlertContext);
    const { toggle2FaEnable } = useContext(AuthContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();

    const [step, setStep] = useState(ENABLE_AUTHENTICATION_STEPS.SelectMethod);
    const [isLoading, setLoading] = useState(false);
    const [authType, setAuthType] = useState<AUTHENTICATION_TYPES | null>(null);
    const [secretKey, setSecretKey] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const isSuccessMessageStep =
        step === ENABLE_AUTHENTICATION_STEPS.SuccessMessage;

    const handleClose = (close?: () => void) => {
        close?.();

        if (isSuccessMessageStep) {
            toggle2FaEnable(true, authType!);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Security##2FA##success message##alert")
            });
        }
    };

    const changeAuthenticationType = (newType: AUTHENTICATION_TYPES | null) =>
        setAuthType(newType);

    const changeStep = (newStep: ENABLE_AUTHENTICATION_STEPS) =>
        setStep(newStep);

    const changeOtpCode = (newValue: string) => {
        setError(null);
        setOtpCode(newValue);
    };

    const getSecretKeySubmit = async () => {
        try {
            setLoading(true);

            const {
                data: { authenticator_key }
            } = await postData(ENDPOINTS.TfaGenerateKey);

            setSecretKey(authenticator_key);
            changeStep(ENABLE_AUTHENTICATION_STEPS.SendOtpCode);
        } catch (error) {
            setSecretKey("");
            handleResponse(error);
        }

        setLoading(false);
    };

    const getEmailCodeSubmit = () => {
        const successCallback = () =>
            changeStep(ENABLE_AUTHENTICATION_STEPS.SendEmailCode);

        sendCodeToEmail(setLoading, successCallback, postData, handleResponse);
    };

    const sendOtpCodeSubmit = async () => {
        try {
            setLoading(true);

            await postData(ENDPOINTS.TfaConfirm, {
                otp_code: otpCode,
                two_factor_auth_type: authType
            });

            changeStep(ENABLE_AUTHENTICATION_STEPS.SuccessMessage);
        } catch (error) {
            const err = error as AxiosError;

            if (err.response?.status === 400) {
                setError(t("Security##2FA##send otp code##incorrect code"));
            } else {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    return {
        authType,
        error,
        isLoading,
        otpCode,
        secretKey,
        step,
        changeAuthenticationType,
        changeOtpCode,
        changeStep,
        getEmailCodeSubmit,
        getSecretKeySubmit,
        handleClose,
        sendOtpCodeSubmit,
        setError,
        setOtpCode,
        setSecretKey
    };
};

export { useEnableTwoFactorAuthentication };
