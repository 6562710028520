/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import RevertLimits from "./RevertLimits";
import ConfirmationDialog from "./ConfirmationDialog";
import { useCustomResourceLimits } from "./hooks";
import { LIMIT_TYPES, LOADERS } from "./types";

import DialogActions from "../../components/DialogActions";

import { DialogProps } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

import {
    RESOURCE_LIMIT_SETTINGS,
    maxDialogWidth,
    useMediaQueries
} from "../../../../../shared";

const CustomResourceLimits = ({ isOpen, close }: DialogProps) => {
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const {
        rootCompany,
        limitType,
        companies,
        users,
        tasks_per_group,
        configuration_files_per_device,
        isAtLeastOneHigher,
        hasNoErrors,
        error,
        isLoading,
        isConfirmationOpen,
        handleCloseDialog,
        setDefaultValues,
        handleChangeData,
        resetData,
        handleSubmit,
        openConfirmationDialog,
        closeConfirmationDialog
    } = useCustomResourceLimits(isOpen, close);

    return (
        <>
            <Dialog
                title={t("General##custom resource limits")}
                description={t(
                    "Dialog##custom resource limits##main modal description"
                )}
                isTitleSeparator
                isActionsSeparator
                open={isOpen}
                close={handleCloseDialog}
                submit={openConfirmationDialog}
                TransitionProps={{
                    onExited: () => resetData()
                }}
                actions={
                    <DialogActions
                        onClose={handleCloseDialog}
                        isLoading={isLoading[LOADERS.Submit]}
                        isReadyToConfirm={isAtLeastOneHigher && hasNoErrors}
                        submitBtnTooltipText={
                            !isAtLeastOneHigher && hasNoErrors
                                ? t(
                                      "Dialog##custom resource limits##at least one limit must be higher"
                                  )
                                : ""
                        }
                        confirmationButtonText={t("Button##save")}
                    />
                }
                extendToMaxWidth={toMd}
                css={css(fromMd && maxDialogWidth)}
            >
                <div
                    css={css({
                        margin: "8px 0 16px 0",

                        "& > div + div": {
                            marginTop: "16px"
                        }
                    })}
                >
                    {limitType === LIMIT_TYPES.Custom && (
                        <RevertLimits setDefaultValues={setDefaultValues} />
                    )}

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t("Dialog##company")}
                        value={rootCompany?.name}
                        readOnly
                    />

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t(
                            "Dialog##custom resource limits##resources limit type"
                        )}
                        value={limitType ? t(`General##${limitType}`) : ""}
                        readOnly
                        isLoading={isLoading[LOADERS.Data]}
                    />

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t("General##companies")}
                        labelIcon={t(
                            "Dialog##custom resource limits##explanations##companies"
                        )}
                        name={RESOURCE_LIMIT_SETTINGS.Companies}
                        value={companies}
                        onChange={handleChangeData}
                        isLoading={isLoading[LOADERS.Data]}
                        readOnly={isLoading[LOADERS.Submit]}
                        errorText={error[RESOURCE_LIMIT_SETTINGS.Companies]}
                    />

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t("General##users")}
                        labelIcon={t(
                            "Dialog##custom resource limits##explanations##users"
                        )}
                        name={RESOURCE_LIMIT_SETTINGS.Users}
                        value={users}
                        onChange={handleChangeData}
                        isLoading={isLoading[LOADERS.Data]}
                        readOnly={isLoading[LOADERS.Submit]}
                        errorText={error[RESOURCE_LIMIT_SETTINGS.Users]}
                    />

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t(
                            "Dialog##custom resource limits##tasks per group"
                        )}
                        labelIcon={t(
                            "Dialog##custom resource limits##explanations##tasks per group"
                        )}
                        name={RESOURCE_LIMIT_SETTINGS.TasksPerGroup}
                        value={tasks_per_group}
                        onChange={handleChangeData}
                        isLoading={isLoading[LOADERS.Data]}
                        readOnly={isLoading[LOADERS.Submit]}
                        errorText={error[RESOURCE_LIMIT_SETTINGS.TasksPerGroup]}
                    />

                    <InputField
                        fullWidth
                        size={INPUT_FIELD_SIZES.Medium}
                        labelLeft={t(
                            "Dialog##custom resource limits##configuration files per device"
                        )}
                        labelIcon={t(
                            "Dialog##custom resource limits##explanations##configuration files per device"
                        )}
                        name={
                            RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice
                        }
                        value={configuration_files_per_device}
                        onChange={handleChangeData}
                        isLoading={isLoading[LOADERS.Data]}
                        readOnly={isLoading[LOADERS.Submit]}
                        errorText={
                            error[
                                RESOURCE_LIMIT_SETTINGS
                                    .ConfigurationFilesPerDevice
                            ]
                        }
                    />
                </div>
            </Dialog>

            <ConfirmationDialog
                isOpen={isConfirmationOpen}
                companyName={rootCompany?.name}
                companiesCount={companies}
                usersCount={users}
                tasksPerGroupCount={tasks_per_group}
                configurationFilesPerDeviceCount={
                    configuration_files_per_device
                }
                close={closeConfirmationDialog}
                handleSubmit={handleSubmit}
            />
        </>
    );
};

export default CustomResourceLimits;
