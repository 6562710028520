/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Trans } from "react-i18next";

import { TermsAndConditionsProps } from "./types";

import Checkbox from "../../DesignComponents/Checkbox";
import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";

import ThemeContext from "../../../context/theme/themeContext";
import { useTranslatedExternalLinks } from "../../../shared";

const TermsAndConditions = ({
    isDisabled,
    toggleValue
}: TermsAndConditionsProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { dataProcessingAgreement, termsOfService } =
        useTranslatedExternalLinks();

    return (
        <div
            css={css({
                borderTop: `1px solid ${gray200}`
            })}
        >
            <Checkbox
                label={
                    <Trans
                        i18nKey="Auth##user consent text"
                        components={{
                            1: (
                                <TextLink
                                    size={TEXT_LINK_SIZES.Normal}
                                    href={termsOfService}
                                    target="_blank"
                                    isDisabled={isDisabled}
                                    css={css({ display: "inline" })}
                                >
                                    Terms of Service
                                </TextLink>
                            ),
                            2: (
                                <TextLink
                                    size={TEXT_LINK_SIZES.Normal}
                                    href={dataProcessingAgreement}
                                    target="_blank"
                                    isDisabled={isDisabled}
                                    css={css({ display: "inline" })}
                                >
                                    Data Processing Agreement
                                </TextLink>
                            )
                        }}
                    />
                }
                disabled={isDisabled}
                onChange={(_, value) => toggleValue(value)}
                customStyle={{ margin: "16px 0" }}
            />
        </div>
    );
};

export default TermsAndConditions;
