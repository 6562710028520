/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Button from "../../../../DesignComponents/Button";
import {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button/types/enums";

import { TELTONIKA_TELEMATICS_WEBSITE_CONTACTS } from "../../../../../shared";

const CompanyLevelCallToAction = () => {
    return (
        <div
            css={css({
                justifyContent: "center",
                textAlign: "center",
                "> span": {
                    display: "flex",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.15px"
                },

                "> span:first-of-type": {
                    paddingTop: "24px"
                }
            })}
        >
            <span>
                Get in touch with Teltonika Telematics Sales representative, ask
                your questions and learn more.
            </span>
            <Button
                data-testid="call-to-action-button"
                color={BUTTON_COLORS.Primary}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.TextOnly}
                css={css({
                    margin: "16px 0 24px 0"
                })}
                onClick={() =>
                    window.open(
                        TELTONIKA_TELEMATICS_WEBSITE_CONTACTS,
                        "_blank",
                        "noopener"
                    )
                }
            >
                Contact sales
            </Button>
        </div>
    );
};

export default CompanyLevelCallToAction;
