import { useContext, useEffect, useRef, useState } from "react";

import { Company, ItemSizesRef, SECTION_TITLES } from "../types";

import ParamsContext from "../../../../context/params/paramsContext";
import { FILTER_NAMES, Filter, sortAndReturnArray } from "../../../../shared";

export const useListCompanies = (searchValue: string) => {
    const {
        companyFilteredValues,
        filterDropdownOptions,
        setQueryParams,
        toggleTableRowDeselection,
        resetFilterParams
    } = useContext(ParamsContext);

    const listRef = useRef({});
    const itemSizesRef = useRef<ItemSizesRef>({});

    const [listHeight, setListHeight] = useState(422);
    const [allCompanies, setAllCompanies] = useState<Company[]>([]);

    useEffect(() => {
        const getSearchResults = (companies: Filter[]) =>
            companies.filter(company =>
                company.name.toLowerCase().includes(searchValue.toLowerCase())
            );

        const getAllCompanies = () => {
            const companies =
                filterDropdownOptions[FILTER_NAMES.CompanyId] || [];

            const companiesAfterSearch = getSearchResults(companies);

            const filteredValuesAfterSearch = getSearchResults(
                companyFilteredValues
            );

            const notSelectedOptions = companiesAfterSearch.filter(
                company =>
                    companyFilteredValues.findIndex(
                        selectedCompany => selectedCompany.id === company.id
                    ) === -1
            );

            const filteredLength = filteredValuesAfterSearch.length;
            const notFilteredLength = notSelectedOptions.length;

            return [
                ...(!filteredLength && notFilteredLength
                    ? [
                          {
                              id: "null",
                              name: SECTION_TITLES.AllCompanies,
                              count: notFilteredLength
                          } as Company
                      ]
                    : []),
                ...(filteredLength
                    ? [
                          {
                              id: "null",
                              name: SECTION_TITLES.SelectedCompanies,
                              count: filteredLength
                          } as Company
                      ]
                    : []),
                ...sortAndReturnArray(filteredValuesAfterSearch, "name"),
                ...(filteredLength && notFilteredLength
                    ? [
                          {
                              id: "null",
                              name: SECTION_TITLES.OtherCompanies,
                              count: notFilteredLength
                          } as Company
                      ]
                    : []),
                ...sortAndReturnArray(notSelectedOptions, "name")
            ];
        };

        setAllCompanies(getAllCompanies());
    }, [searchValue, companyFilteredValues, filterDropdownOptions]);

    // Set list height
    useEffect(() => {
        const sizeArray = Object.values(itemSizesRef.current);

        const sum = allCompanies.reduce(
            (total, _, index) => total + itemSizesRef.current[index],
            0
        );

        const height =
            allCompanies.length > 12 ||
            sizeArray.length === 0 ||
            allCompanies.length > sizeArray.length
                ? 422
                : sum;

        const finalHeight = Math.min(422, height);

        setListHeight(finalHeight);
    }, [allCompanies]);

    const updateSelectedCompaniesArray = (newCompany: Filter) => {
        const currentSelectedCompanies = [...companyFilteredValues];

        const index = currentSelectedCompanies.findIndex(
            company => company.id === newCompany.id
        );

        if (index === -1) {
            return [...currentSelectedCompanies, newCompany];
        } else {
            currentSelectedCompanies.splice(index, 1);
            return currentSelectedCompanies;
        }
    };

    const sendToQueryParams = (newCompany: Filter) => {
        const updatedSelectedCompanies =
            updateSelectedCompaniesArray(newCompany);

        const paramsPayload = {
            [FILTER_NAMES.CompanyId]: updatedSelectedCompanies.map(
                singleValue => String(singleValue.id)
            )
        };

        const filteredValuesPayload = {
            filterName: FILTER_NAMES.CompanyId,
            value: updatedSelectedCompanies
        };

        setQueryParams({
            params: paramsPayload,
            resetPage: true,
            filteredValues: filteredValuesPayload
        });

        toggleTableRowDeselection();
    };

    const getItemKey = (index: number, data: any) => {
        const item: Filter = data.companies[index];
        const { id, name } = item;

        return id === "null" ? name : id;
    };

    const getItemSize = (index: number) => {
        const defaultHeight = allCompanies[index].id === "null" ? 32 : 48;

        return itemSizesRef.current[index] || defaultHeight;
    };

    return {
        listRef,
        itemSizesRef,
        allCompanies,
        companyFilteredValues,
        listHeight,
        sendToQueryParams,
        getItemKey,
        getItemSize,
        resetFilterParams
    };
};
