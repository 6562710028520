import qs from "query-string";

import {
    ENDPOINTS,
    FILTER_NAMES,
    NOT_ASSIGNED,
    ORDERS,
    Query,
    QueryParams,
    Resource,
    TABLE_NAMES,
    isInternalTypeCompany,
    parseResource
} from "../../../shared";

export const getDescription = (
    option: any,
    isAdminUser: boolean,
    resource: string,
    descriptionResource: Resource | undefined
) => {
    if (isAdminUser && resource === TABLE_NAMES.Companies) {
        const idLabel = `ID: ${option.id}`;

        const codeLabel =
            option.code === null || option.code === ""
                ? ""
                : `; LZ: ${option.code}`;

        return { description: `${idLabel}${codeLabel}` };
    }

    return descriptionResource
        ? {
              description: parseResource(descriptionResource, option)
          }
        : {};
};

export const getFilteredData = (
    dataFromQuery: Query[],
    exceptions?: number | Array<number>,
    hasOnlyInternalCompanies?: boolean,
    hasNotAssigned?: boolean
) => {
    const filterInternalCompanies = (data: Query[]) => {
        const dataCopy = [...data];

        if (hasOnlyInternalCompanies) {
            return dataCopy.filter(data =>
                isInternalTypeCompany(data.transferCompanyInfo?.companyType)
            );
        }

        return dataCopy;
    };

    const filterExceptions = (data: Query[]) => {
        if (exceptions) {
            const isArray = Array.isArray(exceptions);
            const exceptionsArray = isArray ? exceptions : [exceptions];

            return data.filter(({ id }) => !exceptionsArray.includes(id));
        }

        return data;
    };

    const dataAfterInternalCompaniesFilter =
        filterInternalCompanies(dataFromQuery);

    const dataAfterExceptionsFilter = filterExceptions(
        dataAfterInternalCompaniesFilter
    );

    if (hasNotAssigned) {
        dataAfterExceptionsFilter.unshift({
            id: -1,
            name: NOT_ASSIGNED
        });
    }

    return dataAfterExceptionsFilter;
};

export const getCompanyInfo = (option: any, resourceName: string) => {
    if (resourceName === ENDPOINTS.Groups) {
        return {
            companyInfo: {
                companyId: option.company_id,
                parentName: option.company.name
            }
        };
    }

    return {};
};

export const getTransferCompanyInfo = (option: any) => ({
    transferCompanyInfo: {
        lzCode: option.code,
        vipStatus: option.vip_status || null,
        companyType: option.type || null,
        companyLevel: option.level || null,
        ...(option.company
            ? {
                  parentCompany: {
                      id: option.company.id,
                      name: option.company.name
                  }
              }
            : {})
    }
});

export const getRootCompanyParams = (
    allQueryParams: QueryParams,
    isRootRequired?: boolean
) =>
    isRootRequired
        ? {
              [FILTER_NAMES.RootCompany]:
                  allQueryParams[FILTER_NAMES.RootCompany]
          }
        : {};

export const getResourceParams = (params: string) =>
    params
        ? qs.parse(params, {
              arrayFormat: "comma"
          })
        : {};

export const getSortParams = (resourceName: string, isAdminUser: boolean) => {
    const isFilesResource = resourceName === ENDPOINTS.Files;
    const isCompaniesResource = resourceName === ENDPOINTS.Companies;

    if (isFilesResource) {
        return { sort: "id", order: ORDERS.Descending };
    }

    return isAdminUser && isCompaniesResource
        ? {}
        : { sort: "name", order: ORDERS.Ascending };
};

export const getQueryParams = (query: string) => (query ? { query } : {});
