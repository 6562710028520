import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import DialogActions from "../../components/DialogActions";
import { COMPANY_ACTIONS, DialogProps } from "../../../types";
import Dialog from "../../../../DesignComponents/Dialog";
import QueryDropdown from "../../../../QueryDropdown";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import AuthContext from "../../../../../context/auth/authContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    ID_TYPE,
    Query,
    TABLE_NAMES,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const CreateCompany = ({ isOpen, close }: DialogProps) => {
    const { user } = useContext(AuthContext);
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { toMd } = useMediaQueries();

    const [dropdownValue, setDropdownValue] = useState<Query | null>(null);
    const [newCompanyName, setNewCompanyName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const response = await postData(ENDPOINTS.Companies, {
                name: newCompanyName,
                company_id: dropdownValue?.id
            });

            handleCloseDialog();
            setReloadItems(TABLE_NAMES.Companies);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    const handleDropdownChange = (companyValue: Query | null) =>
        setDropdownValue(companyValue);

    const changeValue = (e: { target: { value: string } }) =>
        setNewCompanyName(e.target.value);

    const handleCloseDialog = () => close(COMPANY_ACTIONS.CreateCompany);

    return (
        <Dialog
            title={t("Dialog##create company")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setDropdownValue(null);
                    setNewCompanyName("");
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={dropdownValue && newCompanyName.trim()}
                    confirmationButtonText={t("Button##create")}
                    cancelButtonTestId="cancel-create-company"
                    confirmationButtonTestId="confirm-create-company"
                />
            }
            extendToMaxWidth={toMd}
        >
            <QueryDropdown
                data-testid="create-company-query-dropdown"
                resource={ENDPOINTS.Companies}
                idType={ID_TYPE.Id}
                value={dropdownValue}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                isRequired
                readOnly={isLoading}
                change={(_, val) => handleDropdownChange(val)}
                labelLeft={t("Dialog##parent company")}
                textFieldParams={{ placeholder: t("Dialog##select") }}
                customStyle={{ marginBottom: "16px" }}
                isRootRequired
            />

            <InputField
                data-testid="company-name-input-field"
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                required
                readOnly={isLoading}
                onChange={changeValue}
                labelLeft={t("Dialog##company name")}
                placeholder={`${t("Dialog##example")} ${user?.company.name}`}
            />
        </Dialog>
    );
};

export default CreateCompany;
