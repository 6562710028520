/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

import { IconLinkProps } from "../types";

import { TooltipGeneral } from "../../DesignComponents/Tooltips";
import ThemeContext from "../../../context/theme/themeContext";

const IconLink = ({ link, tooltip, icon }: IconLinkProps) => {
    const {
        colorsFacelift: { blue700, gray700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <TooltipGeneral disableTouchListener title={t(`Auth##${tooltip}`)}>
            <Link
                target="_blank"
                rel="noreferrer"
                href={link}
                underline="hover"
                css={css({
                    display: "inline-flex",
                    color: gray700,

                    "&:hover": {
                        color: blue700
                    }
                })}
            >
                {icon}
            </Link>
        </TooltipGeneral>
    );
};

export default IconLink;
