import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import InlineActions from "../../components/DialogActions";
import { DialogProps, USER_ACTIONS } from "../../../types";
import Dialog from "../../../../DesignComponents/Dialog";
import QueryDropdown from "../../../../QueryDropdown";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import ParamsContext from "../../../../../context/params/paramsContext";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    ID_TYPE,
    Query,
    TABLE_NAMES,
    useApi,
    useEmailValidation,
    useIsMounted,
    useMediaQueries
} from "../../../../../shared";

const InviteUser = ({ isOpen, close }: DialogProps) => {
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const isMounted = useIsMounted();
    const { toMd } = useMediaQueries();

    const [dropdownValue, setDropdownValue] = useState<Query | null>(null);
    const [newUserEmail, setNewUserEmail] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { isEmailValid, emailError, clearEmailError, validateEmail } =
        useEmailValidation(newUserEmail);

    const changeValue = useCallback(
        (e: { target: { value: string } }) => {
            setNewUserEmail(e.target.value.trim());
            validateEmail(e);
            clearEmailError();
        },
        [clearEmailError, validateEmail]
    );

    const handleCloseDialog = useCallback(() => {
        clearEmailError();
        close(USER_ACTIONS.InviteUser);
    }, [close, clearEmailError]);

    const handleSubmit = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await postData(`${ENDPOINTS.UserInvite}/create`, {
                email: newUserEmail,
                company_id: dropdownValue?.id
            });

            if (isMounted) {
                handleCloseDialog();
                setReloadItems(TABLE_NAMES.Users);
                setReloadItems(TABLE_NAMES.UserInvite);
                handleResponse(response);
            }
        } catch (error) {
            if (isMounted) {
                handleResponse(error);
            }
        }

        setIsLoading(false);
    }, [
        dropdownValue,
        handleCloseDialog,
        handleResponse,
        isMounted,
        newUserEmail,
        postData,
        setReloadItems
    ]);

    const handleDropdownChange = (userValue: Query | null) =>
        setDropdownValue(userValue);

    return (
        <Dialog
            title={t("Dialog##invite user")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setDropdownValue(null);
                    setNewUserEmail("");
                }
            }}
            actions={
                <InlineActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={dropdownValue && isEmailValid}
                    confirmationButtonText={t("Button##invite")}
                    cancelButtonTestId="cancel-invite-user"
                    confirmationButtonTestId="confirm-invite-user"
                />
            }
            extendToMaxWidth={toMd}
        >
            <InputField
                data-testid="invite-user-input-field"
                size={INPUT_FIELD_SIZES.Medium}
                id="standard-error-helper-text"
                fullWidth
                required
                readOnly={isLoading}
                value={newUserEmail}
                onBlur={validateEmail}
                errorText={isEmailValid ? "" : emailError}
                onChange={changeValue}
                labelLeft={t("Dialog##email")}
                placeholder={`${t("Dialog##example")} email@fota.com`}
                customStyle={{ marginTop: "8px" }}
            />

            <QueryDropdown
                data-testid="invite-user-query-dropdown"
                resource={ENDPOINTS.Companies}
                idType={ID_TYPE.Id}
                value={dropdownValue}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                isRequired
                readOnly={isLoading}
                change={(_, val) => handleDropdownChange(val)}
                labelLeft={t("Dialog##company")}
                textFieldParams={{ placeholder: t("Dialog##select") }}
                customStyle={{ margin: "16px 0px 6px 0px" }}
                isRootRequired
            />
        </Dialog>
    );
};

export default InviteUser;
