import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useTaskTypes from "../../../Actions/dialogs/Devices/CreateTask/hooks/useTaskTypes";

import ParamsContext from "../../../../context/params/paramsContext";
import TableContext from "../../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    Filter,
    FILTER_NAMES,
    getFilteredCompaniesIds,
    handleFilterOptionsStructure,
    useApi
} from "../../../../shared";

export const useFilterDropdown = (
    filterName: FILTER_NAMES,
    additionalParams?: string
) => {
    const { queryParams, companyFilteredValues } = useContext(ParamsContext);

    const { tableName } = useContext(TableContext);

    const { t } = useTranslation();

    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const { tasks } = useTaskTypes();

    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState<Filter[]>([]);

    useEffect(() => {
        let isSubscribed = true;

        if (isOpen && options.length === 0) {
            getFilterValues(isSubscribed);
        }

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);

            setLoading(false);
        };

        // eslint-disable-next-line
    }, [isOpen]);

    const getFilterValues = async (isSubscribed: boolean) => {
        try {
            setLoading(true);

            const filteredCompanies = getFilteredCompaniesIds(
                companyFilteredValues
            );

            const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];
            const rootCompanyQuery = `&${FILTER_NAMES.RootCompany}=${rootCompanyId}`;

            const filteredCompaniesQuery = filteredCompanies.length
                ? `&${FILTER_NAMES.CompanyId}=${filteredCompanies}`
                : "";

            const additionalQueryParams = additionalParams
                ? `&${additionalParams}`
                : "";

            const resource = `${tableName}/filterList?field=${filterName}${rootCompanyQuery}${filteredCompaniesQuery}${additionalQueryParams}`;

            const { data }: { data: Filter[] } = await getData(resource);

            if (isSubscribed) {
                const sortedOptions = handleFilterOptionsStructure(
                    data,
                    filterName,
                    tableName,
                    tasks,
                    t
                );

                setOptions(sortedOptions);
            }
        } catch (error) {
            if (isSubscribed && !isCanceled(error)) {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    return { isOpen, isLoading, options, setOpen };
};
