/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { PAYLOAD_NAMES, PayloadData } from "../types";

import DialogActions from "../../../components/DialogActions";
import { EditInlineDialogProps } from "../../../types";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";

import AuthContext from "../../../../../../context/auth/authContext";
import {
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries,
    useStringInputValidation
} from "../../../../../../shared";

const EditCompanyInline = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { isSystemUser } = useContext(AuthContext);

    const { updateData, handleResponse } = useApi();
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);

    const [payloadData, setPayloadData] = useState<PayloadData>({
        [PAYLOAD_NAMES.Company]: "",
        [PAYLOAD_NAMES.Code]: ""
    });

    const { company, code } = payloadData;

    const { stringInputError } = useStringInputValidation(code);

    useEffect(() => {
        if (data) {
            const { name, code } = data;

            setPayloadData({ company: name, code: code ?? "" });
        }
        // eslint-disable-next-line
    }, [data]);

    const handleSubmit = async () => {
        const payload = {
            name: company,
            ...(isSystemUser ? { code: code.trim() } : {})
        };

        try {
            setLoading(true);

            const response = await updateData(
                ENDPOINTS.Companies,
                data.id,
                payload
            );

            close();
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const handleNameValueChange = (e: ChangeEvent<HTMLInputElement>) =>
        setPayloadData({ ...payloadData, [e.target.name]: e.target.value });

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit company")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={
                        company.trim() && stringInputError === null
                    }
                />
            }
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <InputField
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                required
                readOnly={isLoading}
                value={company}
                name={PAYLOAD_NAMES.Company}
                onChange={handleNameValueChange}
                labelLeft={t("Dialog##name")}
                placeholder={t("Dialog##enter name")}
                customStyle={{ margin: "8px 0 16px 0" }}
            />

            {isSystemUser && (
                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    value={code}
                    readOnly={isLoading}
                    name={PAYLOAD_NAMES.Code}
                    onChange={handleNameValueChange}
                    labelLeft={t("Table##code")}
                    placeholder={t("Dialog##enter code")}
                    errorText={stringInputError}
                    customStyle={{ marginBottom: "16px" }}
                />
            )}
        </Dialog>
    );
};

export default EditCompanyInline;
