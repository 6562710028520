/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import FileDeleted from "./FileDeleted";
import { FileProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../DesignComponents/Button";
import ThemeContext from "../../../../context/theme/themeContext";
import FileIcon from "../../../../assets/customIcons/FileIcon";
import FileDownloadRoundedIcon from "../../../../assets/customIcons/actions/FileDownloadRoundedIcon";
import {
    checkIfDownloadIsAllowed,
    downloadFile,
    useApi,
    useOverflow
} from "../../../../shared";

const File = ({ data, isSubtitle, ...props }: FileProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { getData, handleResponse } = useApi();
    const { measuredRef, isOverflowing } = useOverflow([data?.name]);

    const [isLoading, setLoading] = useState(false);

    return data ? (
        <div
            {...props}
            css={css([
                {
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    overflowX: "hidden",

                    "& > span": {
                        color: textPlaceholder,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    }
                },
                !isSubtitle && {
                    "@media(max-width: 839px)": {
                        marginLeft: "0",
                        marginTop: "16px",
                        top: "0"
                    }
                }
            ])}
        >
            <>
                {isSubtitle && (
                    <span
                        css={css({
                            marginRight: "5px"
                        })}
                    >
                        {t("General##file")}:
                    </span>
                )}

                <div
                    css={css([
                        {
                            display: "flex",
                            alignItems: "center",
                            flex: "1",
                            overflowX: "hidden",

                            "& > svg": {
                                marginRight: "8px",
                                color: textPlaceholder,
                                fontSize: "16px"
                            },

                            "& > span": {
                                flex: "1",
                                color: isSubtitle ? textDark : gray700,
                                fontSize: isSubtitle ? "14px" : "12px",
                                fontWeight: "600",
                                lineHeight: isSubtitle ? "20px" : "16px",
                                letterSpacing: isSubtitle ? "0.1px" : "0.4px",
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis"
                            },

                            "& > button": {
                                marginLeft: isSubtitle ? "8px" : "16px"
                            },

                            "@media (max-width: 839px)": {
                                flex: "unset"
                            }
                        },

                        !isSubtitle && {
                            borderRadius: "8px",
                            border: `1px solid ${gray200}`,
                            padding: checkIfDownloadIsAllowed(data)
                                ? "6px 16px"
                                : "9px 15px"
                        }
                    ])}
                >
                    {!isSubtitle && <FileIcon />}

                    <TooltipGeneral
                        title={data?.name}
                        placement={TOOLTIP_PLACEMENT.Top}
                        disableHoverListener={!isOverflowing}
                    >
                        <span ref={measuredRef}>{data?.name}</span>
                    </TooltipGeneral>

                    {data && checkIfDownloadIsAllowed(data) && (
                        <TooltipGeneral
                            placement={TOOLTIP_PLACEMENT.Top}
                            title={t("Table##download file")}
                        >
                            <Button
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Tiny}
                                variant={BUTTON_VARIANTS.IconOnly}
                                icon={<FileDownloadRoundedIcon />}
                                isLoading={isLoading}
                                onClick={() =>
                                    downloadFile({
                                        id: data.id,
                                        getData,
                                        handleResponse,
                                        setLoading: () => setLoading(true),
                                        removeLoading: () => setLoading(false)
                                    })
                                }
                            />
                        </TooltipGeneral>
                    )}
                </div>
            </>
        </div>
    ) : (
        <FileDeleted isSubtitle={isSubtitle} />
    );
};

export default File;
