/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SyntheticEvent, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@mui/material";

import CompanyStats from "./CompanyStats";
import {
    PARTIES,
    TRANSFER_COMPANY_ACTIONS,
    TransferDropdownData
} from "./types";

import DialogActions from "../../components/DialogActions";

import { COMPANY_ACTIONS, DialogProps } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import DialogConfirmation from "../../../../DesignComponents/DialogConfirmation";
import SegmentedToggle, {
    TAB_SIZES
} from "../../../../DesignComponents/SegmentedToggle";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../DesignComponents/BannerMessage";
import QueryDropdown from "../../../../QueryDropdown";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

import ThemeContext from "../../../../../context/theme/themeContext";
import AlertContext from "../../../../../context/alert/alertContext";
import AuthContext from "../../../../../context/auth/authContext";
import MoveIcon from "../../../../../assets/customIcons/actions/MoveIcon";
import MergeIcon from "../../../../../assets/customIcons/actions/MergeIcon";
import TransferCompaniesArrowIcon from "../../../../../assets/customIcons/actions/TransferCompaniesArrowIcon";
import {
    COMPANY_TYPES,
    DROPDOWN_SIZES,
    ENDPOINTS,
    ID_TYPE,
    Query,
    isAllowedToEditCompany,
    isClientTypeCompany,
    isInternalTypeCompany,
    useApi
} from "../../../../../shared";

const TransferCompanies = ({ isOpen, close }: DialogProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    const { hasAutoHide, removeAlert, setBackgroundActionId } =
        useContext(AlertContext);

    const { user, isSystemUser } = useContext(AuthContext);

    const { t } = useTranslation();
    const { updateData, postData, handleResponse } = useApi();

    const [isShowConfirmation, setShowConfirmation] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(TRANSFER_COMPANY_ACTIONS.Move);

    const [company, setCompany] = useState<TransferDropdownData>({
        [PARTIES.Source]: null,
        [PARTIES.Target]: null
    });

    const { source, target } = company;

    const isMoveAction = tabValue === TRANSFER_COMPANY_ACTIONS.Move;
    const isMergeAction = tabValue === TRANSFER_COMPANY_ACTIONS.Merge;

    const isReady = source && target;

    // non-primitive value as a dependency, it is memoized to avoid re-rendering
    const sourceExceptions = useMemo(() => {
        return [
            ...(user?.company_id ? [user.company_id] : []),
            ...(target?.id ? [target.id] : [])
        ];
    }, [target?.id, user?.company_id]);

    const setTarget = (sourceData: Query | null) =>
        sourceData?.transferCompanyInfo?.parentCompany || null;

    const handleTabChange = (
        _: SyntheticEvent,
        newValue: TRANSFER_COMPANY_ACTIONS
    ) => {
        setTabValue(newValue);

        const isMergeTab = newValue === TRANSFER_COMPANY_ACTIONS.Merge;

        if (isMergeTab) {
            if (
                isClientTypeCompany(source?.transferCompanyInfo?.companyType) ||
                hasCode(source?.transferCompanyInfo?.lzCode)
            ) {
                setCompany({
                    [PARTIES.Source]: null,
                    [PARTIES.Target]: null
                });
            } else {
                setCompany({
                    ...company,
                    [PARTIES.Target]: setTarget(source)
                });
            }
        }
    };

    const handleDropdownChange = (name: PARTIES, value: Query | null) => {
        const isSource = name === PARTIES.Source;
        const isEmpty = value === null;

        const isSourceInternalType = isInternalTypeCompany(
            value?.transferCompanyInfo?.companyType
        );

        if (isMoveAction) {
            const isTargetNotInternal = !isInternalTypeCompany(
                target?.transferCompanyInfo?.companyType
            );

            if (
                isSource &&
                (isEmpty || (isSourceInternalType && isTargetNotInternal))
            ) {
                setCompany({
                    [PARTIES.Source]: value,
                    [PARTIES.Target]: null
                });
            } else {
                setCompany({
                    ...company,
                    [name]: value
                });
            }
        } else {
            setCompany({
                [PARTIES.Source]: value,
                [PARTIES.Target]: setTarget(value)
            });
        }
    };

    const handleCloseDialog = () => close(COMPANY_ACTIONS.TransferCompanies);
    const handleOpenDialogConfirmation = () => setShowConfirmation(true);
    const handleCloseDialogConfirmation = () => setShowConfirmation(false);

    const handleSubmit = async () => {
        !hasAutoHide && removeAlert();

        if (isReady) {
            handleCloseDialogConfirmation();

            try {
                setLoading(true);

                const payload = isMoveAction
                    ? { company_id: target.id }
                    : {
                          source: source.id,
                          target: target.id
                      };

                const response = isMoveAction
                    ? await updateData(ENDPOINTS.Companies, source.id, payload)
                    : await postData(`${ENDPOINTS.Companies}/merge`, payload);

                if (isMoveAction) {
                    handleCloseDialog();
                    handleResponse(response);
                } else {
                    setBackgroundActionId(
                        response.data,
                        () => handleCloseDialog(),
                        () => setLoading(false)
                    );
                }
            } catch (error) {
                handleResponse(error);
                setLoading(false);
            }
        }
    };

    const getDescription = () => {
        const companyNames = {
            sourceCompany: `"${source?.name}"`,
            targetCompany: `"${target?.name}"`
        };

        const firstPart = isMoveAction
            ? t(
                  "Dialog##confirmations##move companies confirmation",
                  companyNames
              )
            : t(
                  "Dialog##confirmations##merge companies confirmation",
                  companyNames
              );

        const secondPart = t(
            "Dialog##confirmations##are you sure you wish to continue"
        );

        return `${firstPart} ${secondPart}`;
    };

    const hasCode = (code: number | string | null | undefined) =>
        code !== null && code !== "" && code !== undefined;

    const isSourceClientDisabled = (companyType?: COMPANY_TYPES) =>
        isMergeAction && isClientTypeCompany(companyType);

    const isInternalDisabled = (companyType?: COMPANY_TYPES) =>
        !isAllowedToEditCompany(isSystemUser, companyType);

    const isLzCodeDisabled = (code: number | string | null | undefined) =>
        isMergeAction && hasCode(code);

    const getSourceDisabledOptionTooltip = (option: any) => {
        if (isSourceClientDisabled(option.type)) {
            return t(
                "Dialog##merge companies disabled option##client to internal"
            );
        }

        if (isInternalDisabled(option.type)) {
            return t(
                `Dialog##${isMoveAction ? "move" : "merge"} companies disabled option##internal`
            );
        }

        if (isLzCodeDisabled(option.code)) {
            return t("Dialog##merge companies disabled option##lz code");
        }

        return null;
    };

    const isMergeLoading = isMergeAction && isLoading;

    return (
        <>
            <Dialog
                title={t("Dialog##transfer companies")}
                description={t("Dialog##transfer companies description")}
                isTitleSeparator
                isActionsSeparator
                open={isOpen}
                close={isMergeLoading ? undefined : handleCloseDialog}
                extendToMaxWidth
                submit={handleOpenDialogConfirmation}
                TransitionProps={{
                    onExited: () => {
                        !hasAutoHide && removeAlert();
                        setLoading(false);
                        setTabValue(TRANSFER_COMPANY_ACTIONS.Move);

                        setCompany({
                            [PARTIES.Source]: null,
                            [PARTIES.Target]: null
                        });
                    }
                }}
                actions={
                    <DialogActions
                        onClose={handleCloseDialog}
                        isLoading={isLoading}
                        loaderText={
                            isMergeAction ? t("Button##merging") : undefined
                        }
                        isCancelDisabled={isMergeLoading}
                        isReadyToConfirm={isReady}
                        confirmationButtonText={
                            isMoveAction
                                ? t("Button##move")
                                : t("Button##merge")
                        }
                        cancelButtonTestId="cancel-create-task-button"
                        confirmationButtonTestId="confirm-create-task-button"
                    />
                }
            >
                <SegmentedToggle
                    value={tabValue}
                    size={TAB_SIZES.Small}
                    fullWidth
                    onChange={handleTabChange}
                    css={css({
                        marginBottom: "16px"
                    })}
                >
                    <Tab label={t("Button##move")} icon={<MoveIcon />} />
                    <Tab label={t("Button##merge")} icon={<MergeIcon />} />
                </SegmentedToggle>

                {isMergeAction && (
                    <BannerMessage
                        status={BANNER_MESSAGE_STATUSES.Info}
                        title={t("Dialog##merge companies info")}
                        css={css({
                            marginBottom: "16px"
                        })}
                    />
                )}

                <div
                    css={css({
                        display: "flex",
                        alignItems: "center"
                    })}
                >
                    <QueryDropdown
                        key={`${tabValue}-source`}
                        data-testid="source-company-query-dropdown"
                        resource={ENDPOINTS.Companies}
                        idType={ID_TYPE.Id}
                        value={source}
                        size={DROPDOWN_SIZES.Medium}
                        fullWidth
                        getOptionDisabled={(option: Query) =>
                            isSourceClientDisabled(
                                option.transferCompanyInfo?.companyType
                            ) ||
                            isInternalDisabled(
                                option.transferCompanyInfo?.companyType
                            ) ||
                            isLzCodeDisabled(option.transferCompanyInfo?.lzCode)
                        }
                        getOptionDisabledTooltip={option =>
                            getSourceDisabledOptionTooltip(option)
                        }
                        change={(_, val) =>
                            handleDropdownChange(PARTIES.Source, val)
                        }
                        labelLeft={t("Table##source company")}
                        textFieldParams={{
                            placeholder: t("Dialog##select")
                        }}
                        readOnly={isLoading}
                        exceptions={sourceExceptions}
                    />

                    <TransferCompaniesArrowIcon
                        css={css({
                            color: gray700,
                            fontSize: "16px",
                            margin: "24px 8px 0 8px"
                        })}
                    />

                    {isMoveAction ? (
                        <QueryDropdown
                            key={`${tabValue}-target`}
                            data-testid="target-company-query-dropdown"
                            resource={ENDPOINTS.Companies}
                            idType={ID_TYPE.Id}
                            value={target}
                            size={DROPDOWN_SIZES.Medium}
                            fullWidth
                            change={(_, val) =>
                                handleDropdownChange(PARTIES.Target, val)
                            }
                            labelLeft={t("Table##target company")}
                            textFieldParams={{
                                placeholder: t("Dialog##select")
                            }}
                            disabled={source === null}
                            readOnly={isLoading}
                            exceptions={source?.id}
                            hasOnlyInternalCompanies={isInternalTypeCompany(
                                source?.transferCompanyInfo?.companyType
                            )}
                        />
                    ) : (
                        <InputField
                            data-testid="target-company-input-field"
                            size={INPUT_FIELD_SIZES.Medium}
                            fullWidth
                            readOnly
                            labelLeft={t("Table##target company")}
                            value={target?.name || ""}
                        />
                    )}
                </div>

                <CompanyStats
                    source={source}
                    target={target}
                    isMoveAction={isMoveAction}
                />
            </Dialog>

            <DialogConfirmation
                isOpen={isShowConfirmation}
                description={getDescription()}
                onClose={handleCloseDialogConfirmation}
                onSubmit={handleSubmit}
                confirmBtnTitle={t("Button##yes")}
            >
                {isMergeAction && (
                    <BannerMessage
                        status={BANNER_MESSAGE_STATUSES.Warning}
                        title={t("Dialog##confirm cannot revert")}
                        css={css({
                            margin: "8px 0"
                        })}
                    />
                )}
            </DialogConfirmation>
        </>
    );
};

export default TransferCompanies;
