/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";

import { CONTENT_NAMES, InitialContentProps } from "./types";
import { DIALOG_NAMES } from "../types";

import ListChoice from "../../../../DesignComponents/ListChoice";
import Separator from "../../../../DesignComponents/Separator";
import CompanyLevelTag from "../../../../CompanyLevel/shared/components/CompanyLevelTag";

import ThemeContext from "../../../../../context/theme/themeContext";
import AuthContext from "../../../../../context/auth/authContext";
import ApiTokensIcon from "../../../../../assets/customIcons/actions/ApiTokensIcon";
import SecurityIcon from "../../../../../assets/customIcons/actions/SecurityIcon";
import LogOutIcon from "../../../../../assets/customIcons/actions/LogOutIcon";
import TutorialIcon from "../../../../../assets/customIcons/actions/TutorialIcon";
import { COMPANY_LEVELS } from "../../../../../shared";

const InitialContent = ({ setContent, openDialog }: InitialContentProps) => {
    const {
        colorsFacelift: {
            beigeDefault,
            blue100,
            blue700,
            gray200,
            gray800,
            textDark,
            white
        }
    } = useContext(ThemeContext);

    const { user, logoutUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const companyLevel = user?.company_level as COMPANY_LEVELS;

    const getAvatarColors = () => {
        switch (companyLevel) {
            case COMPANY_LEVELS.Basic:
                return { background: gray200, color: textDark };
            case COMPANY_LEVELS.Standard:
                return { background: beigeDefault, color: textDark };
            case COMPANY_LEVELS.Premium:
                return { background: gray800, color: white };
            default:
                return { background: blue100, color: blue700 };
        }
    };

    const avatarBgColor = getAvatarColors().background;
    const avatarTextColor = getAvatarColors().color;

    return (
        <Fragment>
            <li
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "12px 16px"
                })}
            >
                <div
                    css={css({
                        minWidth: "24px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: avatarBgColor,
                        borderRadius: "100px",
                        marginRight: "8px",
                        color: avatarTextColor,
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    })}
                >
                    {user?.email.charAt(0).toUpperCase()}
                </div>

                <div
                    css={css({
                        "& > div": {
                            color: textDark,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                            wordBreak: "break-all"
                        }
                    })}
                >
                    <div
                        css={css({
                            fontWeight: "600",
                            marginBottom: "4px"
                        })}
                    >
                        {user?.email}
                    </div>

                    <div>
                        <span
                            css={css({
                                display: "inline-block",
                                lineHeight: "16px",
                                marginRight: "4px"
                            })}
                        >
                            {t("General##company level")}:
                        </span>

                        <CompanyLevelTag
                            level={companyLevel}
                            onClick={() =>
                                openDialog(DIALOG_NAMES.CompanyLevel)
                            }
                        />
                    </div>
                </div>
            </li>

            <Separator isListSeparator />

            <ListChoice
                title={t("Button##language")}
                iconLeft={<LanguageIcon />}
                onClick={() => setContent(CONTENT_NAMES.Language)}
            />

            <ListChoice
                title={t("Api tokens##tokens")}
                iconLeft={<ApiTokensIcon />}
                onClick={() => openDialog(DIALOG_NAMES.ApiTokens)}
            />

            <ListChoice
                title={t("Security##title")}
                iconLeft={<SecurityIcon />}
                onClick={() => openDialog(DIALOG_NAMES.Security)}
            />

            <ListChoice
                title={t("General##time zone")}
                iconLeft={<AccessTimeRoundedIcon />}
                onClick={() => setContent(CONTENT_NAMES.TimeZone)}
            />

            <ListChoice
                title={t("General##tutorials")}
                iconLeft={<TutorialIcon />}
                onClick={() => setContent(CONTENT_NAMES.TutorialMode)}
            />

            <Separator isListSeparator />

            <ListChoice
                title={t("Button##logout")}
                iconLeft={<LogOutIcon />}
                onClick={logoutUser}
            />
        </Fragment>
    );
};

export default InitialContent;
