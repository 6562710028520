/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    CompanyAndGroupDropdownsProps,
    DROPDOWN_NAME,
    DeviceData,
    ResourceData
} from "./types";

import QueryDropdown from "../../../../../QueryDropdown";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../DesignComponents/BannerMessage";

import AuthContext from "../../../../../../context/auth/authContext";
import {
    ID_TYPE,
    Query,
    ENDPOINTS,
    DROPDOWN_SIZES
} from "../../../../../../shared";

export const CompanyAndGroupDropdowns = ({
    isLoading,
    isCompanyRequired,
    isRootRequired,
    hasClearHistory,
    setPayload
}: CompanyAndGroupDropdownsProps) => {
    const { isSupportUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const [device, setDevice] = useState<DeviceData>({
        [DROPDOWN_NAME.Company]: null,
        [DROPDOWN_NAME.Group]: null
    });

    const [resource, setResource] = useState<ResourceData>({
        [DROPDOWN_NAME.Company]: ENDPOINTS.Companies,
        [DROPDOWN_NAME.Group]: ENDPOINTS.Groups
    });

    const { company, group } = device;
    const { company: resourceCompany, group: resourceGroup } = resource;

    useEffect(() => {
        const isReady = isCompanyRequired ? company : company || group;

        if (isReady) {
            const data = {
                company: company ? company.id : null,
                group: !group || group.id === -1 ? null : group.id
            };

            setPayload(data);
        } else {
            setPayload(null);
        }
        // eslint-disable-next-line
    }, [isCompanyRequired, company, group]);

    const handleChange = useCallback(
        (dropdownValue: Query | null, dropdownName: DROPDOWN_NAME) => {
            const companyInfo = dropdownValue?.companyInfo;

            if (dropdownName === DROPDOWN_NAME.Company) {
                setDevice({
                    [DROPDOWN_NAME.Company]: dropdownValue,
                    [DROPDOWN_NAME.Group]: null
                });
            } else {
                const isCompanyEmpty =
                    dropdownValue?.id &&
                    dropdownValue.id !== -1 &&
                    company === null;

                setDevice({
                    ...device,
                    [dropdownName]: dropdownValue,
                    ...(isCompanyEmpty && {
                        [DROPDOWN_NAME.Company]: {
                            id: companyInfo?.companyId!,
                            name: companyInfo?.parentName!
                        }
                    })
                });
            }

            const getGroupValue = () => {
                if (dropdownName === DROPDOWN_NAME.Company) {
                    return dropdownValue === null
                        ? ENDPOINTS.Groups
                        : `${ENDPOINTS.Groups}?company_id=${dropdownValue.id}`;
                } else {
                    return dropdownValue === null || dropdownValue.id === -1
                        ? resourceGroup
                        : `${ENDPOINTS.Groups}?company_id=${companyInfo?.companyId}`;
                }
            };

            setResource({
                ...resource,
                [DROPDOWN_NAME.Group]: getGroupValue()
            });
        },

        [company, device, resource, resourceGroup]
    );

    const dropdownCSS = css({
        ".MuiCircularProgress-root": {
            display: "none"
        },

        ".MuiInputAdornment-root": {
            marginRight: 0
        }
    });

    return (
        <div
            css={css({
                marginBottom: "16px",

                "& > div + div": {
                    marginTop: "16px"
                }
            })}
        >
            <QueryDropdown
                data-testid="device-company-query-dropdown"
                resource={resourceCompany}
                idType={ID_TYPE.Id}
                value={company}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                change={(_, val) => handleChange(val, DROPDOWN_NAME.Company)}
                labelLeft={t("Table##company")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                customStyle={{ marginTop: "16px" }}
                isRequired={isCompanyRequired}
                css={dropdownCSS}
                isRootRequired={isRootRequired}
                readOnly={isLoading}
            />

            <QueryDropdown
                data-testid="device-group-query-dropdown"
                resource={resourceGroup}
                idType={ID_TYPE.Id}
                value={group}
                hasNotAssigned={!isCompanyRequired}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                change={(_, val) => handleChange(val, DROPDOWN_NAME.Group)}
                labelLeft={t("Table##group")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                css={dropdownCSS}
                disabled={!company}
                readOnly={isLoading}
                tooltipTitle={
                    !company
                        ? t("Dialog##field disabled until company selected")
                        : ""
                }
            />

            {hasClearHistory && isSupportUser && (
                <BannerMessage
                    status={BANNER_MESSAGE_STATUSES.Info}
                    title={t("Dialog##clear device history banner")}
                />
            )}
        </div>
    );
};

export default CompanyAndGroupDropdowns;
