/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
    useContext,
    useState,
    Fragment,
    SyntheticEvent,
    useCallback
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
    BUTTON_TYPES,
    PASSWORD_TYPES,
    PasswordFormProps,
    SHOW_PASSWORD,
    TooltipPswProps
} from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../DesignComponents/InputField";
import PasswordValidation from "../../DesignComponents/PasswordValidation";
import TermsAndConditions from "../../Privacy/TermsAndConditions";

import AuthContext from "../../../context/auth/authContext";
import { ENDPOINTS, useApi, useUrlQueryParams } from "../../../shared";

const PasswordsForm = ({
    inputs,
    button,
    redirectToDevices,
    hasTermsAndConditions
}: PasswordFormProps) => {
    const { isLoading } = useContext(AuthContext);

    const { postData, handleResponse } = useApi();
    const { t } = useTranslation();
    const { getUrlParams } = useUrlQueryParams();
    const { token: queryToken, email: queryEmail } = getUrlParams();
    const { token } = useParams<{ token: string }>();

    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [isPasswordReady, setIsPasswordReady] = useState(false);
    const [hasAcceptedTos, setHasAcceptedTos] = useState(false);

    const [password, setPassword] = useState({
        current: "",
        repeat: "",
        newPsw: ""
    });

    const { current, repeat, newPsw } = password;

    const isReady =
        isPasswordReady && (hasAcceptedTos || !hasTermsAndConditions);

    const toggleDisable = useCallback((value: boolean) => {
        setIsPasswordReady(value);
    }, []);

    const handleChange = useCallback(
        (e: { target: { name: string; value: string } }) => {
            const inputName = e.target.name;
            const inputValue = e.target.value;

            setPassword({
                ...password,
                [inputName]: inputValue
            });
        },
        [password]
    );

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (isReady) {
            try {
                setSubmitLoading(true);

                const response =
                    button === BUTTON_TYPES.SignUp
                        ? await postData(
                              `${ENDPOINTS.UserInvite}/accept/${token}`,
                              {
                                  password: newPsw,
                                  user_consent: hasAcceptedTos
                              }
                          )
                        : await postData(ENDPOINTS.ResetPassword, {
                              reset_token: queryToken,
                              password: newPsw,
                              email: queryEmail
                          });

                handleResponse(response);
                redirectToDevices();
            } catch (error) {
                setSubmitLoading(false);
                handleResponse(error);
            }
        }
    };

    return (
        <form noValidate onSubmit={handleSubmit}>
            {inputs.map(
                (input: {
                    TooltipPsw: TooltipPswProps;
                    name: string;
                    title: string;
                }) => {
                    const { name, title } = input;

                    const showPswRequirements =
                        name === PASSWORD_TYPES.Repeat &&
                        (button === BUTTON_TYPES.SignUp ||
                            button === BUTTON_TYPES.ResetPsw);

                    const { PasswordVisibility, showPassword } =
                        input.TooltipPsw;

                    return (
                        <Fragment key={name}>
                            <InputField
                                fullWidth
                                size={INPUT_FIELD_SIZES.Medium}
                                type={
                                    showPassword[
                                        name as keyof typeof showPassword
                                    ]
                                        ? SHOW_PASSWORD.Show
                                        : SHOW_PASSWORD.Hide
                                }
                                labelLeft={title}
                                name={name}
                                placeholder={t("General##enter here")}
                                onChange={handleChange}
                                iconRight={<PasswordVisibility />}
                                readOnly={isSubmitLoading || isLoading}
                                css={css({
                                    marginBottom:
                                        name !== PASSWORD_TYPES.Repeat
                                            ? "16px"
                                            : 0
                                })}
                            />

                            {showPswRequirements && (
                                <div
                                    css={css({
                                        margin: "16px 0"
                                    })}
                                >
                                    <PasswordValidation
                                        name={button}
                                        currentPsw={current}
                                        newPsw={newPsw}
                                        repeatPsw={repeat}
                                        onRulesMet={toggleDisable}
                                    />
                                </div>
                            )}
                        </Fragment>
                    );
                }
            )}

            {hasTermsAndConditions && (
                <TermsAndConditions
                    isDisabled={isSubmitLoading || isLoading}
                    toggleValue={setHasAcceptedTos}
                />
            )}

            <Button
                data-testid="psw-form-submit-button"
                fullWidth
                color={BUTTON_COLORS.Primary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                type="submit"
                disabled={!isReady}
                isLoading={isSubmitLoading || isLoading}
            >
                {t(`Auth##${button}`)}
            </Button>
        </form>
    );
};

export default PasswordsForm;
